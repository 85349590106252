import { request } from "../utils";
import { BANNER_API, USER_BANNER_API } from "./constants";

export const getBannerList = () => {
  return request.get(BANNER_API);
};

export const getBannerProducts = (id, sort, page) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${BANNER_API}-products/${id}${queryParams}`);
};

export const getUserBannerProducts = (id, sort, page) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${USER_BANNER_API}-products/${id}${queryParams}`);
};

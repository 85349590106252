import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/configure-store";
import { PersistGate } from "redux-persist/integration/react";
import "./styles/index.scss";

if (document.getElementById("root")) {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/index";
import Sidebar from "./components/Sidebar";
import { AlertProvider } from "./components/Alerts/AlertsContext";
import Alert from "./components/Alerts/Alert";

import Home from "./pages/home";
import Animated404Page from "./pages/404";
import SignIn from "./pages/sign-in";
import ForgotPassword from "./pages/forgot-password";
import CheckOut from "./pages/checkout";
import OrderSummary from "./pages/order-summary";
import ProductDetail from "./pages/product-detail";
import Register from "./pages/register";
import CategoryPage from "./pages/category-page";
import SearchPage from "./pages/search";
import BestPage from "./pages/best";
import NewestPage from "./pages/newest";
import Terms from "./pages/terms";
import PrivacyPolicy from "./pages/privacy-policy";
import ResetPassword from "./pages/reset-password";

import FavoriteListPage from "./pages/favorite-list";
import UserProfile from "./pages/user-profile";
import AddressManagement from "./pages/address-management";
import OrderList from "./pages/order-list";
import OrderDetail from "./pages/order-detail";
import Brand from "./pages/brand";
import ChooseAddress from "./pages/choose-address";
import ArticlePage from "./pages/article-page";
import BannerProductPage from "./pages/banner-products";
import PaymentInfo from "./pages/payment-info";

const Router = () => {
  return (
    <BrowserRouter>
      <AlertProvider>
        <Alert />
        <Routes>
          <Route path="*" element={<Animated404Page />} />
          <Route element={<Layout />}>
            <Route exact path="/" element={<Home />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/order-summary/:id" element={<OrderSummary />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/category/:id" element={<CategoryPage />} />
            <Route path="/search-product" element={<SearchPage />} />
            <Route path="/best-product" element={<BestPage />} />
            <Route path="/brand/:id" element={<Brand />} />
            <Route path="/newest-product" element={<NewestPage />} />
            <Route path="/payment-info" element={<PaymentInfo />} />
            <Route path="/article/:id" element={<ArticlePage />} />
            <Route
              path="/banner-products/:id"
              element={<BannerProductPage />}
            />
            <Route element={<Sidebar />}>
              <Route exact path="/favorite" element={<FavoriteListPage />} />
              <Route exact path="/user-profile" element={<UserProfile />} />
              <Route exact path="/order-list" element={<OrderList />} />
              <Route exact path="/order-detail/:id" element={<OrderDetail />} />
              <Route exact path="/choose-address" element={<ChooseAddress />} />
              <Route
                exact
                path="/address-management"
                element={<AddressManagement />}
              />
            </Route>
          </Route>
        </Routes>
      </AlertProvider>
    </BrowserRouter>
  );
};

export default Router;

import React, { useState, useEffect, useContext, useMemo } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getBaseURL } from '../utils/envUtils';
import { addToCart, deleteCart, clearCart } from '../api/cart';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import CustomInput from '../components/CustomInput';
import { checkoutSchema } from "../schemas/checkoutSchema";
import { formatNumber } from "../utils/common"
import {
    getShippingAddressList,
} from '../api/shipping-address';
import {
    calculatePrice, calculateShippingCost, combinedProductName,
    calculateTotalAmount, calculateTotalQuantity, calculateTotalWeight
} from '../utils/calculate';
import { getCartList } from '../api/cart';
import store from "../redux/configure-store";
import { setCart } from "../redux/ducks/cart";
import { HiMinusSm, HiOutlineTrash, HiOutlinePlusSm } from "react-icons/hi";
import { createOrder } from '../api/order';
import { AlertContext } from '../components/Alerts/AlertsContext';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const CheckOut = () => {
    const { showAlert } = useContext(AlertContext);
    const [defaultAddress, setDefaultAddress] = useState({});
    const navigate = useNavigate();
    const carts = useSelector((state) => state.cart);
    const baseURL = getBaseURL();
    const subTotal = calculateTotalAmount(carts);
    const totalQuantity = calculateTotalQuantity(carts)
    const totalWeight = calculateTotalWeight(carts)
    const { shop } = useSelector((state) => state.shop);
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(null);
    const totalShippingCost = useMemo(() => {
        const addressCityLower = defaultAddress.shipping_city?.toLowerCase();
        const addressLower = defaultAddress.shipping_address?.toLowerCase();

        // 检查是否为海外直邮并且地址包含新疆或西藏
        const isSpecialArea = (addressCityLower?.includes('新疆') || addressCityLower?.includes('西藏') || addressLower?.includes('新疆') || addressLower?.includes('西藏')) && selectedDeliveryMethod?.type === "oversea-direct";

        // 检查是否为奥克兰或地址包含奥克兰
        const isAuckland = addressCityLower?.includes('auckland') || addressLower?.includes('auckland') || addressCityLower?.includes('奥克兰') || addressLower?.includes('奥克兰');

        if (isSpecialArea) {
            return totalWeight ? calculateShippingCost(totalWeight, shop?.overseas_direct_special_area_shipping_fee) : 0;
        } else if (selectedDeliveryMethod?.type === "oversea-direct") {
            return totalWeight ? calculateShippingCost(totalWeight, shop?.shipping_cost) : 0;
        } else if (selectedDeliveryMethod?.type === "local-direct") {
            return isAuckland ? Number(shop?.local_shipping_cost) : Number(shop?.out_auckland_local_shipping_fee);
        }
        return 0;
    }, [selectedDeliveryMethod, totalWeight, defaultAddress, shop]);

    const cartTotalAmount = formatNumber(subTotal + totalShippingCost);
    const exchange_rate = useSelector((state) => state.shop?.shop?.shop_exchange_rate);

    const handleIncrement = async (cart) => {
        const response = await addToCart({ product_id: cart?.product?.id, quantity: 1, variant_id: cart?.variant?.id });
        store.dispatch(setCart(response.data.carts));
    };

    const handleDecrement = async (cart) => {
        const response = await addToCart({ product_id: cart?.product?.id, quantity: -1, variant_id: cart?.variant?.id });
        store.dispatch(setCart(response.data.carts));
    };

    const goDeleteCart = async (cart) => {
        const response = await deleteCart(cart?.id)
        store.dispatch(setCart(response.data.carts));
    }
    const handleSubmit = async (values) => {
        // 中文字符及数字的正则表达式
        // const chineseRegex = /^[\u4e00-\u9fa5\da-zA-Z\s,，。.-]+$/;
        // 英文字符及数字的正则表达式
        const englishRegex = /^[a-zA-Z\d\s,.-]+$/;
        // const idRegex = /^(\d{15}|\d{17}[\dxX])$/;
        if (values['recipient-country'] === '中国') {
            // 检查中文地址信息
            // if (!chineseRegex.test(values['recipient-address']) ||
            //     !chineseRegex.test(values['recipient-city']) ||
            //     !chineseRegex.test(values['recipient-name']) ||
            //     !(/^[\d]+$/).test(values['recipient-phone']) || // 纯数字验证电话号码
            //     (values['recipient-id'] && !(/^[a-zA-Z0-9]+$/).test(values['recipient-id']))) { // 检查身份证号码是否为数字和字母的组合
            //     showAlert('error', '地址不正确，请使用中文填写地址信息。');
            //     return;
            // }
            // if (values['recipient-id'] && !idRegex.test(values['recipient-id'])) {
            //     showAlert('error', '身份证号码格式不正确,请输入正确的15位或18位身份证号码。');
            //     return;
            // }
        } else if (values['recipient-country'] === 'New Zealand') {
            // 检查英文地址信息
            if (!englishRegex.test(values['recipient-address']) ||
                !englishRegex.test(values['recipient-city']) ||
                !englishRegex.test(values['recipient-name']) ||
                !(/^[\d]+$/).test(values['recipient-phone'])) { // 纯数字验证电话号码
                showAlert('error', '地址不正确，请使用英文填写地址信息。');
                return;
            }
        }
        if (!deliveryMethods.length) {
            showAlert('error', '不能生成订单，没有可用的运送方式。');
            return;
        }
        try {
            const shippingInfo = {
                sender_name: values['sender-name'],
                sender_address: values['sender-address'],
                sender_contact: values['sender-phone'],
                shipping_address: values['recipient-address'],
                shipping_city: values['recipient-city'],
                shipping_contact: values['recipient-phone'],
                shipping_country: values['recipient-country'],
                shipping_id: values['recipient-id'],
                shipping_name: values['recipient-name'],
                shipping_type: selectedDeliveryMethod?.type,
                user_remark: values['user_remark'],
            };
            const response = await createOrder(carts, shippingInfo, totalWeight, totalShippingCost, subTotal, totalQuantity);
            clearCart();
            navigate(`/order-summary/${response.data.order.order_number}`);
        } catch (error) {
            console.error('Order creation failed:', error);
        }
    };

    const handleSelectAddress = () => {
        navigate(`/choose-address`);
    }

    const fetchAddressList = async () => {
        try {
            const response = await getShippingAddressList();
            const addressData = response.data.shipping_addresses.data;
            if (addressData.length > 0) {
                const defaultAddr = addressData.find(address => address.is_default);
                if (defaultAddr) {
                    setDefaultAddress(defaultAddr);
                } else {
                    setDefaultAddress({
                        shipping_country: "中国"
                    });
                }
            } else {
                setDefaultAddress({
                    shipping_country: "中国"
                });
            }
        } catch (error) {
            console.error('Error', error);
            setDefaultAddress({
                shipping_country: "中国"
            });
        }
    };

    const handleSenderName = (name) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            sender_name: name,
        }));
    };

    const handleSenderPhone = (phone) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            sender_phone: phone,
        }));
    };

    const handleSenderAddress = (address) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            sender_address: address,
        }));
    };
    const handleRecipientName = (name) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            shipping_name: name,
        }));
    };
    const handleRecipientPhone = (contact) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            shipping_contact: contact,
        }));
    };
    const handleUserRemark = (user_remark) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            user_remark: user_remark,
        }));
    };

    const handleCountrySelect = (country) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            shipping_country: country,
        }));
    };

    const handleCitySelect = (city) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            shipping_city: city,
        }));
    };

    const handleAddressSelect = (address) => {
        setDefaultAddress(prevAddress => ({
            ...prevAddress,
            shipping_address: address,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await getCartList();
            store.dispatch(setCart(response.data.carts));
        };
        fetchData();
    }, []);

    useEffect(() => {
        fetchAddressList()
    }, []);

    useEffect(() => {
        const isChina = defaultAddress.shipping_country === '中国';
        const isNZ = defaultAddress.shipping_country === 'New Zealand';
        const addressCityLower = defaultAddress.shipping_city?.toLowerCase();
        const addressLower = defaultAddress.shipping_address?.toLowerCase();

        // 检查是否包含特殊区域
        const isAuckland = (addressCityLower?.includes('auckland') || addressLower?.includes('auckland'));
        const isInSpecialArea = (addressCityLower?.includes('新疆') || addressCityLower?.includes('西藏') || addressLower?.includes('新疆') || addressLower?.includes('西藏'));

        // 更新配送方式
        const updatedDeliveryMethods = [
            {
                id: 1,
                type: 'oversea-direct',
                title: '邮寄中国',
                turnaround: shop?.overseas_shipping_note,
                price: isInSpecialArea ? '$' + shop?.overseas_direct_special_area_shipping_fee + '/kg' : '$' + shop?.shipping_cost + '/kg'
            },
            {
                id: 2,
                type: 'local-direct',
                title: '邮寄新西兰境内',
                turnaround: shop?.local_shipping_note,
                price: isAuckland ? "$" + shop?.local_shipping_cost : "$" + shop?.out_auckland_local_shipping_fee
            },
            {
                id: 3,
                type: 'local-pick',
                title: '奥克兰本地自取',
                turnaround: shop?.pick_up_note,
                price: '$0.00'
            }
        ].filter((method) => {
            switch (method.type) {
                case 'oversea-direct':
                    return isChina && shop.is_overseas_shipping === 1;
                case 'local-direct':
                    return isNZ && shop.is_local_shipping === 1;
                case 'local-pick':
                    return isNZ && shop.is_pick_up === 1;
                default:
                    return false;
            }
        });

        setDeliveryMethods(updatedDeliveryMethods);
        setSelectedDeliveryMethod(updatedDeliveryMethods[0] || null);
    }, [shop, defaultAddress]);


    return (
        <Formik
            initialValues={{
                'sender-name': defaultAddress.sender_name || "",
                'sender-phone': defaultAddress.sender_phone || "",
                'sender-address': defaultAddress.sender_address || "",
                'recipient-name': defaultAddress.shipping_name || "",
                'recipient-phone': defaultAddress.shipping_contact || "",
                'recipient-address': defaultAddress.shipping_address || "",
                'recipient-city': defaultAddress.shipping_city || "",
                'recipient-country': defaultAddress.shipping_country || "",
                'recipient-id': defaultAddress.shipping_id || "",
                'user_remark': defaultAddress.user_remark || "",
                shipping_type: 'oversea-direct', // 默认发货方式
            }}
            validationSchema={checkoutSchema}
            onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                setSubmitting(false);
            }}
            enableReinitialize
        >
            {({ isSubmitting }) => (
                <Form className="bg-gray-50 mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                        <div>
                            <div>
                                <h2 className="text-lg font-medium text-gray-900">发件人信息</h2>
                                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                    <CustomInput onBlur={(e) => handleSenderName(e.target.value)} name="sender-name" label="名字" type="text" />
                                    <CustomInput onBlur={(e) => handleSenderPhone(e.target.value)} name="sender-phone" label="联系电话" type="text" />
                                </div>
                                <CustomInput onBlur={(e) => handleSenderAddress(e.target.value)} name="sender-address" label="地址" type="text" />
                            </div>
                            <div className="mt-10 border-t border-gray-200 pt-10">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-lg font-medium text-gray-900">收件人信息</h2>
                                    <button
                                        className="text-xs flex items-center bg-indigo-500 hover:bg-indigo-600 text-white font-medium py-1 px-2 rounded"
                                        onClick={handleSelectAddress} // 假设您已定义了 handleSelectAddress 函数
                                    >
                                        <svg className="w-4 h-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M3 7h4l10.5 9.5-4.5 4.5L3 7z"></path>
                                            <path d="M13 17h8"></path>
                                        </svg>
                                        选择收件人
                                    </button>
                                </div>
                                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                    <CustomInput name="recipient-name" label="名字" type="text" onBlur={(e) => handleRecipientName(e.target.value)} />
                                    <CustomInput name="recipient-phone" label="联系电话" type="text" onBlur={(e) => handleRecipientPhone(e.target.value)} />
                                </div>
                                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">

                                    <CustomInput
                                        name="recipient-country"
                                        label="国家"
                                        type="select"
                                        onChange={(e) => handleCountrySelect(e.target.value)}
                                    >
                                        <option value="中国">中国</option>
                                        <option value="New Zealand">New Zealand</option>
                                    </CustomInput>
                                    <CustomInput name="recipient-city" label="市/省" type="text" onBlur={(e) => handleCitySelect(e.target.value)} />
                                </div>
                                <CustomInput name="recipient-address" label="地址" type="textarea" onBlur={(e) => handleAddressSelect(e.target.value)} />
                                <CustomInput name="user_remark" label="订单备注" type="textarea" onBlur={(e) => handleUserRemark(e.target.value)} />
                                {/* {selectedDeliveryMethod?.type === 'oversea-direct' && (
                                    <CustomInput name="recipient-id" label="身份证号码" type="text" />
                                )} */}
                            </div>
                            <div className="mt-10 border-t border-gray-200 pt-10">
                                <RadioGroup value={selectedDeliveryMethod} onChange={setSelectedDeliveryMethod}>
                                    <RadioGroup.Label className="text-lg font-medium text-gray-900">发货方式</RadioGroup.Label>

                                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                        {deliveryMethods?.length ? deliveryMethods?.map((deliveryMethod) => (
                                            <RadioGroup.Option
                                                key={deliveryMethod?.id}
                                                value={deliveryMethod}
                                                className={({ checked, active }) =>
                                                    classNames(
                                                        checked ? 'border-transparent' : 'border-gray-300',
                                                        active ? 'ring-2 ring-indigo-500' : '',
                                                        'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                                                    )
                                                }
                                            >
                                                {({ checked, active }) => (
                                                    <>
                                                        <span className="flex flex-1">
                                                            <span className="flex flex-col">
                                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                    {deliveryMethod.title}
                                                                </RadioGroup.Label>
                                                                <RadioGroup.Description
                                                                    as="span"
                                                                    className="mt-1 flex items-center text-sm text-gray-500"
                                                                >
                                                                    {deliveryMethod.turnaround}
                                                                </RadioGroup.Description>
                                                                <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                                                    {deliveryMethod.price}
                                                                </RadioGroup.Description>
                                                            </span>
                                                        </span>
                                                        {checked ? <CheckCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" /> : null}
                                                        <span
                                                            className={classNames(
                                                                active ? 'border' : 'border-2',
                                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                                'pointer-events-none absolute -inset-px rounded-lg'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        )) : '无发货方式'}
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>

                        {/* Order summary */}
                        <div className="mt-10 lg:mt-0">
                            <h2 className="text-lg font-medium text-gray-900">订单汇总</h2>

                            <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
                                <h3 className="sr-only">Items in your cart</h3>
                                <ul role="list" className="divide-y divide-gray-200">
                                    {carts?.length ? carts?.map((cart) => {
                                        const { product, variant, user } = cart;
                                        return (
                                            <li key={product?.id} className="flex py-6">
                                                <div className="flex-shrink-0">
                                                    <img src={product?.avatar
                                                        ? (product?.images?.find((image) => image?.id == product?.avatar))?.path
                                                        : baseURL + "/images/avatar/no-picture.jpg"} alt={product?.imageAlt} className="w-20 rounded-md" />
                                                </div>

                                                <div className="ml-6 flex flex-1 flex-col">
                                                    <div className="flex">
                                                        <div className="min-w-0 flex-1">
                                                            <h4 className="text-sm">
                                                                <Link to={`/product/${product?.id}`}>{combinedProductName(product, variant)}</Link>
                                                            </h4>
                                                            <p className="mt-1 text-sm text-gray-500">{product?.color}</p>
                                                            <p className="mt-1 text-sm text-gray-500">{product?.size}</p>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-1 items-center justify-between pt-2">
                                                        <p className="mt-1 text-sm font-medium text-gray-900">¥&nbsp;{formatNumber(calculatePrice(product, variant, user) * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{formatNumber(calculatePrice(product, variant, user))}</p>

                                                        <div className="flex items-center">
                                                            <div className="p-2 cursor-pointer" onClick={() => handleIncrement(cart)}>
                                                                <HiOutlinePlusSm style={{ fontSize: "1rem" }} />
                                                            </div>
                                                            <p className="text-gray-500">{cart.quantity}</p>
                                                            {cart?.quantity === 1 ? (
                                                                <div
                                                                    onClick={() => goDeleteCart(cart)}
                                                                    className="p-1 cursor-pointer"
                                                                >
                                                                    <HiOutlineTrash style={{ fontSize: "1rem", color: "red" }} />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={() => handleDecrement(cart)}
                                                                    className="p-1 cursor-pointer"
                                                                >
                                                                    <HiMinusSm style={{ fontSize: "1rem" }} />
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    }) : <div className="flex justify-center items-center h-32">购物车为空</div>}
                                </ul>
                                <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <dt className="text-sm">总数量</dt>
                                        <dd className="text-sm font-medium text-gray-900">{totalQuantity}</dd>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <dt className="text-sm">产品总重量(包邮产品重量算0)</dt>
                                        <dd className="text-sm font-medium text-gray-900">{totalWeight}
                                            &nbsp;g</dd>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <dt className="text-sm">小计</dt>
                                        <dd className="text-sm font-medium text-gray-900">¥&nbsp;{formatNumber(subTotal * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{formatNumber(subTotal)}</dd>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <dt className="text-sm">运费</dt>
                                        <dd className="text-sm font-medium text-gray-900">¥&nbsp;{formatNumber(totalShippingCost * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{formatNumber(totalShippingCost)}</dd>
                                    </div>
                                    <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                                        <dt className="text-base font-medium">合计</dt>
                                        <dd className="text-base font-medium text-gray-900">¥&nbsp;{formatNumber(cartTotalAmount * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{(cartTotalAmount)}</dd>
                                    </div>
                                </dl>

                                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none"
                                    >
                                        创建订单
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>
            )
            }
        </Formik >
    )
}

export default CheckOut;
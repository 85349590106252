export const calculateTotalOriginalAmount = (cart) => {
  if (!cart?.length) {
    return 0;
  }

  let totalOriginalAmount = 0;

  cart?.forEach((item) => {
    let itemPrice = 0;

    if (item?.variant) {
      itemPrice = item?.variant?.price;
    } else {
      itemPrice = item?.product?.price;
    }

    totalOriginalAmount += itemPrice * item?.quantity;
  });

  return totalOriginalAmount;
};

export const combinedProductName = (product, variant) => {
  return (
    (product?.is_free_shipping ? "(包邮)" : "") +
    (variant?.name ? " (" + variant?.name + ") " : "") +
    product?.name
  );
};

export const calculateTotalAmount = (cart) => {
  if (!cart?.length) {
    return 0;
  }

  let totalAmount = 0;

  cart.forEach((item) => {
    // 使用 calculatePrice 函数来获取每个项目的价格
    let itemPrice = calculatePrice(item.product, item.variant);

    // 计算并累加此项目的总价格
    totalAmount += parseFloat((itemPrice * Number(item.quantity)).toFixed(1));
  });

  totalAmount = parseFloat(totalAmount.toFixed(1));
  return totalAmount;
};

export const calculateTotalWeight = (cart) => {
  if (!cart?.length) {
    return 0;
  }

  let totalWeight = 0;

  cart?.forEach((item) => {
    let itemWeight = 0;

    if (item?.variant && Number(item?.variant?.weight) > 0) {
      itemWeight = item?.product.is_free_shipping ? 0 : item?.variant?.weight;
    } else {
      itemWeight = item?.product.is_free_shipping ? 0 : item?.product.weight;
    }

    totalWeight += itemWeight * item?.quantity;
  });

  return totalWeight;
};

export const calculateTotalQuantity = (cart) => {
  let totalQuantity = 0;

  if (!cart?.length) {
    return 0;
  }

  cart?.forEach((item) => {
    totalQuantity += item?.quantity;
  });

  return totalQuantity;
};

export const calculateShippingCost = (totalWeight, shipping_cost) => {
  //如果重量小于1kg，算1kg的重量
  if (totalWeight / 1000 < 1) {
    totalWeight = 1000;
  }

  return Number((totalWeight / 1000) * shipping_cost);
};

export const calculatePrice = (product, variant) => {
  let originalPrice = variant ? Number(variant?.price) : Number(product?.price);
  let discountPrice = variant
    ? Number(variant?.discount)
    : Number(product?.discount);
  let memberPrice = variant
    ? Number(variant?.member_price)
    : Number(product?.member_price);

  // 如果特价有效，且低于会员价，则使用特价
  if (discountPrice > 0 && (discountPrice < memberPrice || !memberPrice)) {
    return discountPrice;
  }

  // 如果会员价有效（即低于原价），则使用会员价，否则使用原价
  return memberPrice > 0 && memberPrice < originalPrice
    ? memberPrice
    : originalPrice;
};

import React from 'react';

const Terms = () => {

    const handleClose = () => {
        window.close();
    };

    return (
        <div className="p-4 relative">
            <h1 className="text-xl font-bold text-center mb-4">服务条款</h1>
            <div className="text-justify text-sm">
                <h2 className="text-lg font-bold mt-4">1. 引言</h2>
                <p>欢迎您使用 [公司名称] 的服务！在使用我们的服务之前，请仔细阅读以下服务条款。</p>

                <h2 className="text-lg font-bold mt-4">2. 服务描述</h2>
                <p>[公司名称] 提供包括但不限于 [服务描述]。</p>

                <h2 className="text-lg font-bold mt-4">3. 用户责任</h2>
                <p>用户应遵守所有适用的法律法规，并对使用服务的行为负责。</p>

                <h2 className="text-lg font-bold mt-4">4. 知识产权</h2>
                <p>网站及其原创内容、特性和功能是我们的专有财产。</p>

                <h2 className="text-lg font-bold mt-4">5. 用户帐户</h2>
                <p>用户有责任保护其帐户的安全性，并对所有活动负责。</p>

                <h2 className="text-lg font-bold mt-4">6. 服务变更与终止</h2>
                <p>我们可能随时修改或终止服务，恕不另行通知。</p>

                <h2 className="text-lg font-bold mt-4">7. 免责声明</h2>
                <p>我们的服务是按“现状”提供的，不提供任何形式的明示或暗示保证。</p>

                <h2 className="text-lg font-bold mt-4">8. 限制责任</h2>
                <p>在任何情况下，[公司名称] 对任何间接、附带的损害不承担责任。</p>

                <h2 className="text-lg font-bold mt-4">9. 法律适用</h2>
                <p>本条款的解释和执行受 [国家或地区] 法律管辖。</p>

                <h2 className="text-lg font-bold mt-4">10. 联系方式</h2>
                <p>有关服务条款的问题，请联系 [联系方式]。</p>
            </div>
            <button
                onClick={handleClose}
                className="bg-transparent hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow absolute top-0 right-0 m-4">
                X 关闭
            </button>
        </div>
    );
};

export default Terms;

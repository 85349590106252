import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getOrderById } from '../api/order';
import { createPayment } from "../api/payment"
import { getBaseURL } from '../utils/envUtils';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/common"

const OrderSummary = () => {
    const routeParams = useParams();
    const [order, setOrder] = useState("")
    const carts = order?.order_details;
    const baseURL = getBaseURL();
    const { shop } = useSelector((state) => state.shop);
    const navigate = useNavigate();
    const exchange_rate = useSelector((state) => state.shop?.shop?.shop_exchange_rate);
    const statusMapping = {
        "all": "全部",
        "paid": "已付款",
        "unpaid": "待付款",
        "completed": "已发货",
        "cancelled": "已取消",
        "payment_failed": "支付失败"
    };

    const getOrderStatusMessage = (status) => {
        switch (status) {
            case 'unpaid':
                return '感谢您的订单，请尽快付款，以便我们尽快为您发货。';
            case 'paid':
                return '您的付款已收到，我们正在处理您的订单。';
            case 'completed':
                return '您的订单已发货。';
            case 'cancelled':
                return '您的订单已取消。';
            // 添加更多状态处理逻辑
            default:
                return '感谢您的订单。';
        }
    };

    const handlePayment = async (order) => {
        if (shop?.is_direct_payment_enabled === 1) {
            try {
                // 调用 createPayment API
                const paymentResponse = await createPayment(order.order_number, order.total_price);

                // 检查响应中是否有支付页面 URL
                if (paymentResponse && paymentResponse.paymentPageUrl) {
                    // 如果存在支付页面 URL，则重定向用户到该页面
                    window.location.href = paymentResponse.paymentPageUrl;
                } else {
                    // 如果没有找到支付页面 URL，处理该情况（例如显示错误消息）
                    console.error('Payment page URL not found in response');
                }
            } catch (error) {
                // 捕获并处理任何在请求过程中出现的错误
                console.error('Payment Request Error:', error);
            }
        } else {
            navigate("/payment-info");
        }
    };

    useEffect(() => {
        // 定义一个内部的异步函数
        const fetchOrder = async () => {
            const response = await getOrderById(routeParams.id);
            setOrder(response.data.order);
        };

        // 调用这个异步函数
        fetchOrder();

        // 如果需要进行清理，可以在这里返回一个清理函数
    }, [routeParams?.id]); // 依赖数组包含 routeParams.id，当它改变时重新运行


    if (!order) {
        return <div className='min-h-without-header'></div>
    }

    return (
        <div className="mx-auto px-4 py-8 sm:px-6 sm:py-24 lg:grid lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
            <div className="px-2">
                {/* <h1 className="text-sm font-medium text-indigo-600">Payment successful</h1> */}
                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">感谢您的订购</p>
                <p className="mt-2 text-base text-gray-500">
                    {getOrderStatusMessage(order.status)}
                </p>
                <dl className="mt-6 text-sm font-medium">
                    <dt className="text-gray-900">订单号:</dt>
                    <dd className="mt-2 text-indigo-600">#{order?.order_number}</dd>
                </dl>

                <dl className="mt-16 text-sm font-medium">
                    <dt className="text-gray-900">订单状况:</dt>
                    <dd className="mt-2 text-indigo-600">{statusMapping[order.status]}</dd>
                    {order.status === 'unpaid' && (
                        <button
                            className="mt-2 px-4 py-2 bg-blue-500 text-white text-sm rounded hover:bg-blue-600"
                            onClick={() => handlePayment(order)}
                        >
                            {shop?.is_direct_payment_enabled === 1 ? "微信支付" : "支付方式"}
                        </button>
                    )}
                </dl>

                <ul role="list" className="divide-y divide-gray-200">
                    {carts?.length ? carts?.map((cart) => {
                        const { product } = cart;
                        return (
                            <li key={cart?.id} className="flex py-6">
                                <div className="flex-shrink-0">
                                    <img src={product?.avatar
                                        ? (product?.images?.find((image) => image?.id == product?.avatar))?.path
                                        : baseURL + "/images/avatar/no-picture.jpg"} alt={product?.imageAlt} className="w-20 rounded-md" />
                                </div>

                                <div className="ml-6 flex flex-1 flex-col">
                                    <div className="flex">
                                        <div className="min-w-0 flex-1">
                                            <h4 className="text-sm">
                                                <Link to={`/product/${product?.id}`}>{cart?.product_name}</Link>
                                            </h4>
                                        </div>
                                    </div>

                                    <div className="flex flex-1 items-center justify-between pt-2">
                                        <p className="mt-1 text-sm font-medium text-gray-900">¥&nbsp;{formatNumber(cart?.final_price * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{cart?.final_price}</p>
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-end items-center text-center bold"
                                    style={{ flex: "0 0 40px" }}>
                                    <p className="text-gray-500 text-lg">* {cart.quantity}</p>
                                </div>
                            </li>
                        )
                    }) : <div className="flex justify-center items-center h-32">购物车为空</div>}
                </ul>

                <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                    <div className="flex justify-between">
                        <dt>总数量</dt>
                        <dd className="text-gray-900">{order?.total_quantity}</dd>
                    </div>
                    <div className="flex justify-between">
                        <dt>产品总重量(包邮产品重量算0)</dt>
                        <dd className="text-gray-900">{order?.total_weight}
                            &nbsp;g</dd>
                    </div>
                    <div className="flex justify-between">
                        <dt>小计</dt>
                        <dd className="text-gray-900">¥&nbsp;{formatNumber(order?.total_amount * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{order?.total_amount}</dd>
                    </div>

                    <div className="flex justify-between">
                        <dt>运费</dt>
                        <dd className="text-gray-900">¥&nbsp;{formatNumber(order?.shipping_cost * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{order?.shipping_cost}</dd>
                    </div>

                    <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                        <dt className="text-base">合计</dt>
                        <dd className="text-base">¥&nbsp;{formatNumber(order?.total_price * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{order?.total_price}</dd>
                    </div>
                </dl>

                <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                    <div>
                        <dt className="font-medium text-gray-900">收件人信息</dt>
                        <dd className="mt-2">
                            <address className="not-italic">
                                <span className="block">{order?.shipping_name}</span>
                                <span className="block">{order?.shipping_id}</span>
                                <span className="block">{order?.shipping_contact}</span>
                                <span className="block">{order?.shipping_country}</span>
                                <span className="block">{order?.shipping_city}</span>
                                <span className="block">{order?.shipping_address}</span>
                                <span className="block">{order?.user_remark}</span>
                            </address>
                        </dd>
                    </div>
                </dl>

                <div className="mt-16 border-t border-gray-200 py-6 text-right">
                    <Link to="/" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                        继续购物
                        <span aria-hidden="true"> &rarr;</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default OrderSummary
import React, { useContext } from 'react';
import { userSignUp } from "../api/user";
import { useNavigate, Link } from "react-router-dom";
import { AlertContext } from '../components/Alerts/AlertsContext';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { signUpSchema } from "../schemas/sign-up";
import CustomInput from '../components/CustomInput';

const Register = () => {
    const navigate = useNavigate();
    const { showAlert } = useContext(AlertContext);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await userSignUp(values);
            showAlert('success', '注册成功');
            navigate("/login");
        } catch (error) {
            showAlert('error', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="flex min-h-without-header flex-1 flex-col justify-center px-6 pt-2 pb-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    注册新帐户
                </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <Formik
                        initialValues={{ name: "", email: "", password: "", confirmPassword: "", acceptTerms: false }}
                        validationSchema={signUpSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="space-y-6">
                                <CustomInput
                                    label="用户名"
                                    name="name"
                                    type="text"
                                    placeholder="请输入您的用户名"
                                />
                                <CustomInput
                                    label="电子邮件地址"
                                    name="email"
                                    type="email"
                                    placeholder="请输入您的电子邮件"
                                />
                                <CustomInput
                                    label="密码"
                                    name="password"
                                    type="password"
                                    placeholder="请输入您的密码"
                                />
                                <CustomInput
                                    label="确认密码"
                                    name="password_confirmation"
                                    type="password"
                                    placeholder="请再次输入您的密码"
                                />
                                <div className="flex items-center">
                                    <Field
                                        id="acceptTerms"
                                        name="acceptTerms"
                                        type="checkbox"
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor="acceptTerms" className="ml-2 block text-sm text-gray-900">
                                        我同意 <a href="/terms" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">服务条款</a> 和 <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">隐私政策</a>
                                    </label>
                                </div>
                                <ErrorMessage name="acceptTerms" component="div" className="text-red-500 text-sm !mt-0" />
                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {isSubmitting ? '注册中...' : '注册'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div className="mt-6 text-center">
                        <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                            已经是会员? 登入
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;

import React, { Fragment, useState, useEffect, useContext, useRef } from 'react'
import { Dialog, Popover, Disclosure, Transition } from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux';
import { getCategoryList } from "../api/category";
import SlideOverShippingCarts from "./Ui/SlideOverShippingCarts";
import { NavLink } from 'react-router-dom';
import { userLogout } from "../api/user";
import { AlertContext } from '../components/Alerts/AlertsContext';
import { checkLoginStatus } from "../api/user"
import { setUser } from "../redux/ducks/user";
import { setCart } from "../redux/ducks/cart";
import { setFavorite } from "../redux/ducks/favorite";
import store from "../redux/configure-store";
import { useNavigate } from "react-router-dom";
import { getCartList } from '../api/cart';
import { getFavorites } from '../api/favorite';
import { setSearch } from '../redux/ducks/search';
import { Transition as TransitionFunction } from "react-transition-group";
import { getBaseURL } from "../utils/envUtils";
import UserBox from "./Ui/UserBox";
import {
    calculateTotalQuantity,
} from "../utils/calculate";

const Header = () => {
    const baseURL = getBaseURL();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const { shop } = useSelector((state) => state.shop);
    const [categories, setCategories] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const user = useSelector((state) => state.user);
    const { showAlert } = useContext(AlertContext);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [searchInfo, setSearchInfo] = useState("");
    const cart = useSelector((state) => state.cart);
    const navigate = useNavigate();
    const [isUserBoxOpen, setIsUserBoxOpen] = useState(false);
    const nodeRef = useRef(null);
    const [openCategory, setOpenCategory] = useState(null);
    const [openPopover, setOpenPopover] = useState(null);

    const closePopover = () => {
        setOpenPopover(null);
    }

    const toggleCategory = (category) => {
        if (openCategory === category) {
            setOpenCategory(null);
        } else {
            setOpenCategory(category);
        }

    };

    const handleMouseEnter = () => {
        if (user?.name && totalQuantity > 0) {
            setIsHovered(!isHovered);
        }
        if (totalQuantity === 0) {
            showAlert('info', '购物车为空');
        }
        // You can also add other logic here if needed
    };

    const handleSearchChange = (e) => {
        setSearchInfo(e.target.value);
    }

    const goSearchPage = (e) => {
        e.preventDefault();
        store.dispatch(setSearch(searchInfo));
        navigate("/search-product");
    }

    const handleLogout = async () => {
        await userLogout();
        store.dispatch(setUser({}));
        store.dispatch(setCart([]));
        store.dispatch(setFavorite([]));
        navigate("/");
        showAlert('success', '退出成功');
    }

    const onIconClickHandler = () => {
        setIsUserBoxOpen(!isUserBoxOpen);
    }

    const onClose = () => {
        setIsUserBoxOpen(false);
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getCategoryList();
                setCategories(response.data.categories[0].category?.subcategories)
            } catch (error) {
                console.error("Error", error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        let isMounted = true; // 用于跟踪组件挂载状态的标志

        const checkUserStatus = async () => {
            if (user?.name) {
                const response = await checkLoginStatus();
                if (response?.data?.isLoggedIn === false) {
                    store.dispatch(setUser({}));
                    store.dispatch(setCart([]));
                    store.dispatch(setFavorite([]));
                    showAlert('info', '登陆信息已过期，请重新登陆');
                    navigate("/login");
                } else if (isMounted) { // 只有当组件还挂载时才更新状态
                    const cartResponse = await getCartList();
                    store.dispatch(setCart(cartResponse.data.carts));
                    const favorites = await getFavorites();
                    store.dispatch(setUser(response.data.user));
                    store.dispatch(setFavorite(favorites.data.favorites.data));
                    setTotalQuantity(calculateTotalQuantity(cartResponse.data.carts))
                }
            }
        };

        checkUserStatus();

        // 清理函数
        return () => {
            isMounted = false; // 组件卸载时，更新标志
        };
    }, [navigate, store]); // 添加所需依赖


    useEffect(() => {
        setTotalQuantity(calculateTotalQuantity(cart))
    }, [cart])

    //用户登陆登出区域
    const userArea = () => {
        if (user && user?.name && user?.name.trim() !== "") {
            return (
                <div className="flex flex-1 items-center justify-end space-x-6">
                    <div className="text-sm font-medium text-white hover:text-gray-100 lg:block">
                        Hi , {user?.name}
                    </div>
                    <span className="h-6 w-px bg-gray-600 hidden lg:block" aria-hidden="true" />
                    <NavLink
                        onClick={handleLogout}
                        className="text-sm font-medium text-white hover:text-gray-100 lg:block">
                        退出
                    </NavLink>
                </div>
            );
        } else {
            return (
                <div className="flex flex-1 items-center justify-end space-x-6">
                    <NavLink to="/register" className="text-sm font-medium text-white hover:text-gray-100 lg:hidden">
                        注册
                    </NavLink>
                    <NavLink to="/login" className="text-sm font-medium text-white hover:text-gray-100 lg:hidden">
                        登录
                    </NavLink>
                    <span className="h-6 w-px bg-gray-600 hidden lg:block" aria-hidden="true" />
                    <NavLink to="/register" className="text-sm font-medium text-white hover:text-gray-100 hidden lg:block">
                        注册
                    </NavLink>
                    <NavLink to="/login" className="text-sm font-medium text-white hover:text-gray-100 hidden lg:block">
                        登录
                    </NavLink>
                </div>
            );
        }
    }



    return (
        <>
            {/* Mobile menu */}
            <Transition.Root show={mobileMenuOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileMenuOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                                <div className="flex px-4 pb-2 pt-5">
                                    <button
                                        type="button"
                                        className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                {/* Links */}
                                {categories?.map((category) => (
                                    <Disclosure key={category?.name + openCategory} as="div" className="border-b border-gray-200">
                                        {() => (
                                            <>
                                                <Disclosure.Button
                                                    className="flex justify-between w-full px-4 py-4 text-base font-medium text-left text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                                                    onClick={() => toggleCategory(category?.name)}
                                                >
                                                    <span>{category?.name}</span>
                                                    <ChevronDownIcon
                                                        className={`${openCategory === category?.name ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                                                    />
                                                </Disclosure.Button>
                                                {openCategory === category?.name ? <div className="px-4 pt-4 pb-2 space-y-2">
                                                    {category?.subcategories?.map((item) => (
                                                        <NavLink to={"/category/" + item?.id} key={item?.name} onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-gray-700 hover:bg-gray-50">
                                                            {item?.name}
                                                        </NavLink>
                                                    ))}
                                                </div> : null}
                                            </>
                                        )}
                                    </Disclosure>
                                ))}


                                {userArea()}

                                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                                    {/* Currency selector */}
                                    <form>

                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root >

            <header className="relative z-10">
                <nav aria-label="Top">
                    {/* Top navigation */}
                    <div className="bg-gray-900">
                        <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                            {/* Logo (lg-) */}
                            <NavLink to={`/`} className="lg:hidden">
                                <span className="sr-only">Your Company</span>
                                <img
                                    src={shop?.shop_image}
                                    alt=""
                                    className="h-8 w-auto"
                                />
                            </NavLink>
                            {/* Currency selector - I kept it hidden on mobile for now */}
                            <form className="hidden lg:block lg:flex-1">

                            </form>

                            <p className="flex-1 text-center text-sm font-medium text-white lg:flex-none">
                                {shop?.shop_slogan}
                            </p>

                            {userArea()}
                        </div>
                    </div>

                    {/* Secondary navigation */}
                    <div className="bg-white">
                        <div className="border-b border-gray-200">
                            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    {/* Logo (lg+) */}
                                    <div className="hidden lg:flex lg:items-center">
                                        <NavLink to={`/`} >
                                            <span className="sr-only">{shop?.shop_name}</span>
                                            <img
                                                className="h-8 w-auto"
                                                src={shop?.shop_image}
                                                alt=""
                                            />
                                        </NavLink>
                                    </div>

                                    <div className="hidden h-full lg:flex">
                                        {/* Mega menus */}
                                        <Popover.Group className="ml-8">
                                            <div className="flex h-full justify-center space-x-8 z-20">
                                                {categories?.map((category) => (
                                                    <Popover key={category?.name} className="flex">
                                                        {() => (
                                                            <>
                                                                <div className="relative flex">
                                                                    <Popover.Button
                                                                        onMouseEnter={() => setOpenPopover(category?.name)}
                                                                        onClick={() => navigate('/category/' + category?.id)}
                                                                        className="!text-gray-900 relative z-10 flex items-center justify-center text-sm font-medium focus:outline-none">
                                                                        {category?.name}
                                                                    </Popover.Button>
                                                                </div>

                                                                {openPopover === category?.name ? <Transition
                                                                    show={true}
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-200"
                                                                    enterFrom="opacity-0"
                                                                    enterTo="opacity-100"
                                                                    leave="transition ease-in duration-150"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Popover.Panel onMouseLeave={() => setOpenPopover(null)} className="absolute inset-x-0 top-full text-sm text-gray-500">
                                                                        {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                                                        <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                                                                        <div className="relative bg-white">
                                                                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                                                                <div className="grid grid-cols-8 gap-x-8 gap-y-10 py-16">
                                                                                    {category?.subcategories?.map((item) => (
                                                                                        <NavLink to={"/category/" + item?.id} key={item?.name} onClick={closePopover} className="group relative">
                                                                                            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                                                                                <img
                                                                                                    src={item?.avatar ? item?.avatar : baseURL + "/images/avatar/no-picture.jpg"}
                                                                                                    alt={item?.imageAlt}
                                                                                                    className="object-cover object-center"
                                                                                                />
                                                                                            </div>
                                                                                            <a href={item.href} className="mt-4 block font-medium text-gray-900 text-center">
                                                                                                <span className="absolute inset-0 z-10" aria-hidden="true" />
                                                                                                {item?.name}
                                                                                            </a>
                                                                                        </NavLink>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Popover.Panel>
                                                                </Transition> : null}
                                                            </>
                                                        )}
                                                    </Popover>
                                                ))}
                                            </div>
                                        </Popover.Group>

                                    </div>

                                    {/* Mobile menu and search (lg-) */}
                                    <div className="flex items-center lg:hidden">
                                        <button
                                            type="button"
                                            className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                                            onClick={() => setMobileMenuOpen(true)}
                                        >
                                            <span className="sr-only">Open menu</span>
                                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                                        </button>

                                        {/* Search */}
                                        <div className="lg:hidden">
                                            <div className="relative pl-4">
                                                <input
                                                    onChange={handleSearchChange}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            goSearchPage(e);
                                                        }
                                                    }}
                                                    type="text"
                                                    placeholder="搜索"
                                                    style={{ height: 35 }}
                                                    className="w-full border rounded-md pr-10 p-2 text-gray-700 mr-2"
                                                />
                                                <span
                                                    onClick={goSearchPage}
                                                    className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 mr-2 cursor-pointer" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-1 items-center justify-end relative">
                                        <div className="flex items-center lg:ml-8">
                                            <div className="flex space-x-8 items-center">
                                                <div className="hidden lg:flex">
                                                    <div className="relative">
                                                        <input
                                                            onChange={handleSearchChange}
                                                            onKeyDown={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    goSearchPage(e);
                                                                }
                                                            }}
                                                            type="text"
                                                            placeholder="搜索"
                                                            style={{ height: 35 }}
                                                            className="border rounded-md pr-10 p-2 text-gray-700 mr-2"
                                                        />
                                                        <span
                                                            onClick={goSearchPage}
                                                            className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 mr-2 cursor-pointer" aria-hidden="true" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <NavLink className="-m-2 p-2 text-gray-400 hover:text-gray-500 relative" onClick={onIconClickHandler}>
                                                    <span className="sr-only">Account</span>
                                                    <UserIcon className="h-6 w-6 z-10" />
                                                </NavLink>
                                                <TransitionFunction
                                                    nodeRef={nodeRef}
                                                    in={isUserBoxOpen}
                                                    timeout={300}
                                                    unmountOnExit
                                                    mountOnEnter
                                                >
                                                    {() => {
                                                        return (
                                                            <>
                                                                {/* Overlay to close UserBox when clicked outside */}
                                                                <div className="fixed inset-0 z-10" onClick={onClose}></div>
                                                                <div
                                                                    ref={nodeRef}
                                                                    className="z-20 absolute top-full right-0 w-[10rem] p-4 bg-palette-card rounded-lg shadow-lg"
                                                                >
                                                                    <UserBox onClose={onClose} />
                                                                </div>
                                                            </>
                                                        );
                                                    }}
                                                </TransitionFunction>

                                            </div>
                                            <span className="mx-4 h-6 w-px bg-gray-200 lg:mx-6" aria-hidden="true" />
                                            <div className="flex items-center">
                                                <NavLink
                                                    onClick={handleMouseEnter}
                                                    to="#"
                                                    className="group -m-2 flex items-center p-2">
                                                    <ShoppingCartIcon
                                                        className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{totalQuantity}</span>
                                                    <span className="sr-only">items in cart, view bag</span>
                                                </NavLink>
                                                <SlideOverShippingCarts isHovered={isHovered} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header;
// import { useLocation } from "react-router-dom";
import en from "../locales/en";
import cn from "../locales/cn";
import { useSelector } from "react-redux";

export const useLanguage = () => {
  const { shop } = useSelector((state) => state.shop);

  // const { search } = useLocation();
  // const params = new URLSearchParams(search);
  // const lang = params.get("lang") || "cn";
  const lang = shop?.language;
  const locale = lang;
  const t = locale === "en" ? en : cn;
  return { t, locale };
};

import { setToken } from "./token";
import { userLogin } from "../api/user";
import { getCartList } from "../api/cart";
import { getFavorites } from "../api/favorite";
import store from "../redux/configure-store";
import { setUser } from "../redux/ducks/user";
import { setCart } from "../redux/ducks/cart";
import { setFavorite } from "../redux/ducks/favorite";

//Unified Admin login Method
const LoginIn = async (params) => {
  const res = await userLogin(params);
  const token = res.data.token || "";
  setToken(token);
  store.dispatch(setUser(res.data.user));
  const cartList = await getCartList();
  store.dispatch(setCart(cartList.data.carts));
  const favoriteList = await getFavorites();
  store.dispatch(setFavorite(favoriteList.data.favorites));
};

export { LoginIn };

import React, { useContext } from 'react';
import { AlertContext } from './AlertsContext';
import { CheckCircleIcon, XMarkIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

function Alert() {
    const { alert, hideAlert } = useContext(AlertContext);

    if (!alert.isOpen) return null;

    let backgroundColor, textColor, icon;
    switch (alert.type) {
        case 'success':
            backgroundColor = 'bg-green-50';
            textColor = 'text-green-800';
            icon = <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />;
            break;
        case 'error':
            backgroundColor = 'bg-red-50';
            textColor = 'text-red-800';
            icon = <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />;
            break;
        case 'info':
            backgroundColor = 'bg-blue-50';
            textColor = 'text-blue-800';
            icon = <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />;
            break;
        default:
            break;
    }

    return (
        <div className={`fixed top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-md ${backgroundColor} p-4 z-50 max-w-md w-full`}>
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    {icon}
                </div>
                <div className="ml-3 flex-1 min-w-0">
                    <p className={`text-sm font-medium ${textColor} truncate`}>{alert.message}</p>
                </div>
                <div className="ml-auto pl-3">
                    <button
                        type="button"
                        className={`inline-flex rounded-md ${backgroundColor} p-1.5 hover:bg-gray-100 focus:outline-none`}
                        onClick={hideAlert}
                    >
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>

    );
}

export default Alert;

import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./rootSaga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userRedux from "./ducks/user";
import shopRedux from "./ducks/shop";
import cartRedux from "./ducks/cart";
import loadingRedux from "./ducks/loading";
import favoriteRedux from "./ducks/favorite";
import searchRedux from "./ducks/search";

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["loadingRedux", "widthRedux"], //Persistent blacklist
};

const reducer = combineReducers({
  user: userRedux,
  shop: shopRedux,
  cart: cartRedux,
  loading: loadingRedux,
  favorite: favoriteRedux,
  search: searchRedux,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = createStore(persistedReducer, {}, applyMiddleware(...middleware));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { persistor };

export default store;

import { NavLink } from "react-router-dom";
import React from "react";
import { AiOutlineHeart, AiOutlineOrderedList, AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { IoLogOutOutline } from "react-icons/io5";
import PropTypes from "prop-types";
import store from "../../redux/configure-store";
import { logoutUser } from "../../redux/ducks/user";

const UserAccountBox = ({ onClose }) => {

  const onLogoutClickHandler = async () => {
    await store.dispatch(logoutUser());
    onClose();
  }

  return (
    <div className="relative z-50">
      <ul>
        <li className="my-1 py-1" onClick={onClose}>
          <NavLink
            className="flex items-center hover:text-palette-primary"
            to={"/favorite"}
          >
            <AiOutlineHeart
              style={{
                fontSize: "1.2rem",
                width: "1.8rem",
              }}
            />
            <span className="font-normal rtl:mr-1 ltr:ml-1">心愿清单</span>
          </NavLink>
        </li>
        <li className="my-1 py-1" onClick={onClose}>
          <NavLink
            className="flex items-center hover:text-palette-primary"
            to={"/order-list"}
          >
            <AiOutlineOrderedList
              style={{
                fontSize: "1.2rem",
                width: "1.8rem",
              }}
            />
            <span className="font-normal rtl:mr-1 ltr:ml-1">订单汇总</span>
          </NavLink>
        </li>
        <li className="my-1 py-1" onClick={onClose}>
          <NavLink
            className="flex items-center hover:text-palette-primary"
            to={"/address-management"}
          >
            <AiOutlineHome
              style={{
                fontSize: "1.2rem",
                width: "1.8rem",
              }}
            />
            <span className="font-normal rtl:mr-1 ltr:ml-1">地址管理</span>
          </NavLink>
        </li>
        <li className="my-1 py-1" onClick={onClose}>
          <NavLink
            className="flex items-center hover:text-palette-primary"
            to={"/user-profile"}
          >
            {/* Use an appropriate user profile icon */}
            {/* Example: <AiOutlineUserProfile /> */}
            <AiOutlineUser
              style={{
                fontSize: "1.2rem",
                width: "1.8rem",
              }}
            />
            <span className="font-normal rtl:mr-1 ltr:ml-1">个人中心</span>
          </NavLink>
        </li>
        <li className="my-1 py-1" onClick={onLogoutClickHandler}>
          <NavLink
            className="flex items-center hover:text-palette-primary"
            to={"/"}
          >
            <IoLogOutOutline
              style={{
                fontSize: "1.5rem",
                width: "1.8rem",
              }}
            />
            <span className="font-normal rtl:mr-1 ltr:ml-1">退出登陆</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

UserAccountBox.propTypes = {
  onClose: PropTypes.func,
};

export default UserAccountBox;

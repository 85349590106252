import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const BrandBox = ({ id, brandName, imageSrc }) => {

  return (
    <NavLink
      to={`/brand/${id}`}
      className="group relative block p-3 lg:p-2 m-1 transition-transform transform hover:scale-105 border border-gray-200 hover:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      aria-label={`Navigate to ${brandName}`}
    >
      <img
        src={imageSrc}
        alt={brandName}
        className="object-contain w-full h-full transition-opacity group-hover:opacity-90"
      />
      <div className="absolute inset-0 bg-opacity-40 hidden lg:block"></div>
    </NavLink>
  );
};

BrandBox.propTypes = {
  id: PropTypes.number.isRequired,
  brandName: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default BrandBox;

import React from "react";
import PropTypes from "prop-types";

export const NextArrow = ({ className, style, onClick }) => {
  const arrowStyle = {
    ...style,
    display: "flex",
    background: "rgba(0, 0, 0, 0.5)", // 使用半透明的深色背景
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.5)", // 添加阴影效果
  };

  return (
    <div
      className={`${className} absolute z-10 right-4 top-1/2 transform -translate-y-1/2 hover:bg-opacity-80`}
      style={arrowStyle}
      onClick={onClick}
    >
    </div>
  );
};

export const PrevArrow = ({ className, style, onClick }) => {
  const arrowStyle = {
    ...style,
    display: "flex",
    background: "rgba(0, 0, 0, 0.5)", // 统一背景风格
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.5)", // 同样添加阴影效果
  };

  return (
    <div
      className={`${className} absolute z-10 left-4 top-1/2 transform -translate-y-1/2 hover:bg-opacity-80`}
      style={arrowStyle}
      onClick={onClick}
    >
    </div>
  );
};

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

// shopSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_SHOP_REQUEST,
  fetchShopSuccess,
  fetchShopFailure,
} from "../ducks/shop";
import { getShop } from "../../api/shop"; // 导入API函数

function* fetchShopSaga() {
  try {
    const shop = yield call(getShop);
    yield put(fetchShopSuccess(shop?.data?.shop_info));
  } catch (error) {
    yield put(fetchShopFailure(error.toString()));
  }
}

export default function* watchShopSaga() {
  yield takeLatest(FETCH_SHOP_REQUEST, fetchShopSaga);
}

// Redux/ducks/favorite.js
export const SET_FAVORITE = "SET_FAVORITE";
export const FETCH_FAVORITE = "FETCH_FAVORITE"; // New

export const setFavorite = (favorite) => ({
  type: SET_FAVORITE,
  payload: favorite,
});

export const fetchFavorite = () => ({
  // New
  type: FETCH_FAVORITE,
});

const initialState = {};

export default function favoriteRedux(state = initialState, action) {
  switch (action.type) {
    case SET_FAVORITE:
      return action.payload;
    default:
      return state;
  }
}

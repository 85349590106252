// Redux/ducks/user.js
export const SET_USER = "SET_USER";
export const FETCH_USER = "FETCH_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const fetchUser = (params) => ({
  type: FETCH_USER,
  payload: params,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

const initialState = "";

export default function userRedux(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from "prop-types";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    if (totalPages <= 1) {
        return null; // Don't render pagination component if there's only one page.
    }

    const getDisplayedPageNumbers = () => {
        const numbers = [];
        const sideLength = 2; // Number of pages on the sides of current page

        for (let i = 1; i <= totalPages; i++) {
            // Always add first two and last two pages
            if (i <= 2 || i > totalPages - 2) {
                numbers.push(i);
            }
            // Add pages around the current page
            else if (i >= currentPage - sideLength && i <= currentPage + sideLength) {
                numbers.push(i);
            }
        }

        return numbers;
    };

    const pageNumbers = getDisplayedPageNumbers();

    return (
        <div className="flex justify-end items-center space-x-2 mb-8 mr-16">
            {pageNumbers.map((number, index) => (
                <>
                    <button
                        key={number}
                        className={`px-4 py-2 border rounded-2 text-sm focus:outline-none
                                    ${number === currentPage
                                ? 'bg-indigo-600 text-white'
                                : 'bg-white text-indigo-600 border-indigo-600 hover:bg-indigo-50'}`}
                        onClick={() => onPageChange(number)}
                    >
                        {number}
                    </button>
                    {(index < pageNumbers.length - 1 && pageNumbers[index + 1] - number > 1) &&
                        <span className="px-2">...</span>}
                </>
            ))}
        </div>
    );
};

Pagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default Pagination;

import React from 'react';
import { useSelector } from 'react-redux';

const PaymentInfo = () => {
    const { shop } = useSelector((state) => state.shop);

    return (
        <div className="p-4 mx-auto max-w-4xl">
            <h1 className="text-2xl md:text-3xl font-bold text-center mb-8">支付方式</h1>
            <div className="flex flex-col md:flex-row justify-around items-center mb-8">
                {shop?.alipay_image && (
                    <div className="text-center mb-4 md:mb-0">
                        <img src={shop.alipay_image} alt="支付宝" className="w-40 h-40 mx-auto object-cover rounded-lg mb-2" />
                        <p className="text-md md:text-lg font-medium">使用支付宝扫一扫进行支付</p>
                    </div>
                )}
                {shop?.wechart_pay_image && (
                    <div className="text-center">
                        <img src={shop.wechart_pay_image} alt="微信支付" className="w-40 h-40 mx-auto object-cover rounded-lg mb-2" />
                        <p className="text-md md:text-lg font-medium">微信用户可扫码支付</p>
                    </div>
                )}
            </div>
            <div className="text-md md:text-lg text-gray-700 bg-gray-100 p-6 rounded-lg">
                <h2 className="font-semibold mb-4">支付注意事项：</h2>
                <ul className="list-disc pl-5 space-y-2">
                    <li>请确认您的支付金额和订单信息无误后进行支付。</li>
                    <li>支付完成后，请截图并保存支付凭证。</li>
                    <li>如需帮助，请及时联系我们的客服，并提供支付凭证。</li>
                    <li>请注意保管好您的支付信息，避免泄露给他人。</li>
                </ul>
            </div>
        </div>
    );
};

export default PaymentInfo;

import { request } from "../utils";
import { SHIPPING_ADDRESS_API } from "./constants";

export const getShippingAddressList = () => {
  return request.get(SHIPPING_ADDRESS_API);
};

export const createShippingAddress = (data) => {
  return request.post(`${SHIPPING_ADDRESS_API}/create`, data);
};

export const updateShippingAddress = (Id, data) => {
  return request.post(`${SHIPPING_ADDRESS_API}/update/${Id}`, data);
};

export const deleteShippingAddress = (Id) => {
  return request.post(`${SHIPPING_ADDRESS_API}/delete/${Id}`);
};

export const setDefaultShippingAddress = (Id) => {
  return request.post(`${SHIPPING_ADDRESS_API}/default/${Id}`);
};


import React, { useEffect, useState } from 'react'
import { getCategoryProducts, getUserCategoryProducts } from "../api/category";
import { useParams } from "react-router-dom";
import Pagination from "../components/Ui/Pagination";
import ProductList from '../components/ProductList'
import ProductSort from '../components/Ui/ProductSort';
import { useSelector } from 'react-redux';

const CategoryPage = () => {
    const routeParams = useParams();
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [categoryName, setCategoryName] = useState('');
    const [selectedRadioBtn, setSelectedRadioBtn] = useState("recommend");
    const user = useSelector(state => state.user);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const onChangeHandler = (e) => {
        setSelectedRadioBtn(e.currentTarget.id);
    }

    useEffect(() => {
        const fetchCategoryProducts = async () => {
            try {
                const Response = user?.name ? await getUserCategoryProducts(routeParams?.id, selectedRadioBtn, currentPage) : await getCategoryProducts(routeParams.id, selectedRadioBtn, currentPage);
                setProducts(Response.data.products);
                setCategoryName(Response.data.category_name);
                setTotalPages(Response.data.total_pages)
            } catch (error) {
                console.error("Error fetching category products:", error);
                // You can also set an error state here if needed
            }
        };
        fetchCategoryProducts();
    }, [routeParams.id, selectedRadioBtn, currentPage]);

    return (
        <div>
            <div className="px-4 py-12 text-center sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{categoryName}</h1>
                <p className="mx-auto mt-3 max-w-xl text-base text-gray-500 sm:mt-4 sm:text-lg">
                    从新西兰直邮到中国的优质商品，为您带来跨境购物的便捷与信赖。
                </p>
            </div>
            <ProductSort
                selectedBtn={selectedRadioBtn}
                onChangeSelectedBtn={onChangeHandler} />
            <ProductList products={products} />
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    )
}

export default CategoryPage;
import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline'; // 确保导入正确的图标
import { useSelector } from 'react-redux';

const ContactFloatingButton = () => {
    const [isVisible, setIsVisible] = useState(true);
    const { shop } = useSelector((state) => state.shop);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        <div className={`hidden md:flex fixed bottom-4 right-4 md:bottom-16 md:right-16 p-4 md:p-6 bg-white rounded-lg shadow-lg md:shadow-2xl transition duration-300 ease-in-out ${!isVisible && 'opacity-0 pointer-events-none'}`}>

            <div className="flex flex-col space-y-4">
                <div className="flex justify-between items-center mb-2">
                    <h3 className="text-xl font-semibold text-gray-900">联系我们</h3>
                    <button onClick={handleClose} className="rounded-full p-2 hover:bg-gray-200 transition">
                        <XMarkIcon className="h-6 w-6 text-gray-700" />
                    </button>
                </div>
                <div className="flex items-center text-gray-800">
                    微信:&nbsp;&nbsp; <img src={shop?.wechart_image} alt="WeChat" className="h-24 w-24 mr-3" />
                </div>
                <div className="flex items-center text-gray-800">
                    QQ:&nbsp;&nbsp; {shop?.qq_number}
                </div>
            </div>
        </div>
    );
};

export default ContactFloatingButton;

import { request } from "../utils";
import { PRODUCT_API, USER_PRODUCT_API } from "./constants";

export const getNewestProducts = (search, sort, page) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${PRODUCT_API}/new/${queryParams}`);
};

export const getSpecialProducts = (search, sort, page) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${PRODUCT_API}/special/${queryParams}`);
};

export const getBestSalesProducts = (search, sort, page) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${PRODUCT_API}/best/${queryParams}`);
};

export const getProduct = (Id) => {
  return request.get(`${PRODUCT_API}/${Id}`);
};

export const getSearchProducts = (search, sort, page) => {
  const queryParams = `?searchTerm=${search}&sort=${sort}&page=${page}`;
  return request.post(`${PRODUCT_API}/search/${queryParams}`);
};

export const getUserProduct = (Id) => {
  return request.get(`${USER_PRODUCT_API}/${Id}`);
};

export const getUserBestSalesProducts = (search, sort, page) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${USER_PRODUCT_API}/best/${queryParams}`);
};

export const getUserNewestProducts = (search, sort, page) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${USER_PRODUCT_API}/new/${queryParams}`);
};

export const getUserSearchProducts = (search, sort, page) => {
  const queryParams = `?searchTerm=${search}&sort=${sort}&page=${page}`;
  return request.post(`${USER_PRODUCT_API}/search/${queryParams}`);
};

import React from 'react';

const PrivacyPolicy = () => {

    const handleClose = () => {
        window.close();
    };
    return (
        <div className="p-4 relative">
            <button
                onClick={handleClose}
                className="bg-transparent hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow absolute top-0 right-0 m-4">
                X 关闭
            </button>
            <h1 className="text-xl font-bold text-center mb-4">隐私政策</h1>
            <div className="text-justify text-sm">
                <h2 className="text-lg font-bold mt-4">1. 引言</h2>
                <p>我们在 [公司名称] 非常重视您的隐私。本隐私政策旨在帮助您了解我们如何收集、使用和保护您提供给我们的信息，以及您如何控制这些信息的使用。</p>

                <h2 className="text-lg font-bold mt-4">2. 信息收集</h2>
                <p>当您使用我们的服务时，我们可能会收集以下类型的信息：</p>
                <ul>
                    <li>个人识别信息（例如姓名、电子邮件地址等）。</li>
                    <li>交易数据，包括您购买的产品或服务详情。</li>
                    <li>浏览行为和设备信息。</li>
                </ul>

                <h2 className="text-lg font-bold mt-4">3. 信息使用</h2>
                <p>我们使用您的信息来：</p>
                <ul>
                    <li>提供和改进我们的服务。</li>
                    <li>与您沟通，例如发送订单更新或营销信息。</li>
                    <li>进行市场分析和研究。</li>
                </ul>

                <h2 className="text-lg font-bold mt-4">4. 信息分享与披露</h2>
                <p>我们不会将您的个人信息出售给第三方。但在以下情况下，我们可能会分享信息：</p>
                <ul>
                    <li>法律要求，例如回应传票或其他法律程序。</li>
                    <li>与我们的服务提供商共享，以支持我们的业务活动。</li>
                </ul>

                <h2 className="text-lg font-bold mt-4">5. 数据安全</h2>
                <p>我们采取各种安全措施来保护您的个人信息免受未经授权的访问和使用。</p>

                <h2 className="text-lg font-bold mt-4">6. 政策更改</h2>
                <p>我们可能会不时更新本隐私政策。我们鼓励您定期查看本页面了解最新信息。</p>

                <h2 className="text-lg font-bold mt-4">7. 联系我们</h2>
                <p>如果您对本隐私政策有任何疑问，请通过 [您的联系方式] 与我们联系。</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;

import { request } from "../utils";
import { CATEGORY_API, USER_CATEGORY_API } from "./constants";

export const getCategoryList = () => {
  return request.get(CATEGORY_API);
};

export const getCategoryProducts = (id, sort = "recommend", page = 1) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${CATEGORY_API}/${id}${queryParams}`);
};

export const getUserCategoryProducts = (id, sort = "recommend", page = 1) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${USER_CATEGORY_API}/${id}${queryParams}`);
};

// export const getCategoryProducts = (id, sort = "cheapest", page) => {
//   const queryParams = sort ? `?sort=${sort}` : ""; // 构建查询参数字符串
//   return request.get(`/categories/${id}${queryParams}?page=${page}`);
// };

import React from "react";
import PropTypes from "prop-types";
import { getBaseURL } from "../utils/envUtils";
import ProductPrice from "./Ui/ProductPrice";
import ProductAction from "./Ui/ProductAction";
import { useNavigate, Link } from "react-router-dom";
import { combinedProductName } from "../utils/calculate";

const ProductList = ({ title, products, link }) => {
    const baseURL = getBaseURL();
    const navigate = useNavigate();

    return (
        <div className="mx-auto max-w-2xl px-1 sm:px-6 lg:max-w-7xl lg:px-8 py-6 lg:py-8">
            {title ? <div className="md:flex md:items-center md:justify-between">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">{title}</h2>
                <Link to={link} className="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block">
                    产看所有{title}
                    <span aria-hidden="true"> &rarr;</span>
                </Link>
            </div> : null}

            <div className="mt-6 grid grid-cols-2 gap-2 lg:grid-cols-4">
                {products?.map((product) => (
                    <div onClick={() => navigate(`/product/${product?.id}`)} key={product?.id} className="cursor-pointer group relative">
                        <div className="relative">
                            <div className="p-4 lg:p-8 bg-white h-40 w-full overflow-hidden rounded-md group-hover:opacity-75 lg:h-72 xl:h-80 flex items-center justify-center">
                                <img
                                    src={
                                        product?.avatar
                                            ? (product?.images?.find((image) => image?.id == product?.avatar))?.path
                                            : baseURL + "/images/avatar/no-picture.jpg"
                                    }
                                    alt={product?.imageAlt}
                                    className="max-h-full max-w-full object-contain"
                                />
                            </div>
                            {Number(product?.virtual_inventory) === 0 ? (
                                <div className="absolute top-1 right-1 sm:top-2 sm:right-2">
                                    <div className="bg-gray-700 text-white text-xs sm:text-sm font-bold px-2 sm:px-3 py-1 rounded-full opacity-90">
                                        售罄
                                    </div>
                                </div>
                            ) : Number(product?.discount) > 0 && ((Number(product?.member_price) >= Number(product?.discount) || (product?.member_price === 0) || (product?.member_price === null))) ? (
                                <div className="absolute top-1 right-1 sm:top-2 sm:right-2">
                                    <div className="bg-red-400 text-white text-xs sm:text-sm font-bold px-2 sm:px-3 py-1 rounded-full opacity-90">
                                        特价
                                    </div>
                                </div>
                            ) : null}
                            {product?.is_free_shipping ? (
                                <div className="absolute top-1 left-1 sm:top-2 sm:left-2">
                                    <div className="bg-blue-500 text-white text-xs sm:text-sm font-bold px-2 sm:px-3 py-1 rounded-full opacity-90">
                                        包邮
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="flex justify-center md:justify-start flex-col flex-grow overflow-hidden">
                            <h3 title={product?.name} className="mt-4 text-sm text-gray-700 sm:text-[12px] md:text-sm text-left whitespace-nowrap overflow-hidden overflow-ellipsis w-5/6">
                                {combinedProductName(product)}
                            </h3>
                        </div>
                        <ProductPrice price={Number(product?.price)?.toFixed(1)} discount={Number(product?.discount)?.toFixed(1)} member_price={Number(product?.member_price)?.toFixed(1)} />
                        <ProductAction product={product} />
                    </div>
                ))}
            </div>
        </div>
    )
}

ProductList.propTypes = {
    title: PropTypes.string.isRequired,
    products: PropTypes.array.isRequired,
    link: PropTypes.string.isRequired,
}

export default ProductList;

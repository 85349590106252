const cn = {
  mainPage: "主页",
  search: "搜索",
  login: "登录",
  logout: "登出",
  favorites: "收藏",
  delete: "删除",
  thereAreNoFavorites: "目前没有收藏的物品",
  signIn: "登录",
  seeCart: "查看购物车",
  product: "产品",
  totalAmount: "总金额",
  totalQuantity: "总数量",
  payableAmount: "应付金额",
  productTotalAmount: "产品总金额",
  loginAndOrder: "登录并下单",
  order: "订单",
  orderSummary: "订单摘要",
  cartIsEmpty: "购物车是空的",
  CategoryOfGoods: "商品分类",
  categories: "分类",
  seeAllProduct: "查看此分类中的所有产品",
  offer: "特价和折扣",
  bestSells: "畅销产品",
  mainMenu: "主菜单",
  language: "语言: ",
  cn: "中文 - Cn",
  en: "英语 - En",
  theme: "主题: ",
  dark: "暗色",
  light: "明亮",
  digital: "数码产品",
  laptop: "笔记本电脑和配件",
  asus: "华硕",
  apple: "苹果",
  dell: "戴尔",
  lenovo: "联想",
  samsung: "三星",
  hp: "惠普",
  huawei: "华为",
  acer: "宏碁",
  msi: "微星",
  mobile: "移动设备和配件",
  nokia: "诺基亚",
  xiaomi: "小米",
  motorola: "摩托罗拉",
  lg: "LG",
  sony: "索尼",
  computer: "计算机和配件",
  monitor: "显示器",
  mouse: "鼠标",
  keyboard: "键盘",
  hard: "硬盘、闪存和固态硬盘",
  other: "其他",
  tablet: "平板电脑",
  powerBank: "移动电源",
  speaker: "蓝牙音箱和有线音箱",
  headphones: "耳机、耳麦和免提耳机",
  fashion: "时尚和服装",
  women: "女装",
  dress: "连衣裙",
  skirt: "裙子",
  jeans: "牛仔裤",
  pants: "裤子",
  tShirt: "T恤",
  scarf: "围巾",
  shirt: "衬衫",
  tie: "领带",
  overalls: "工装裤",
  mittens: "连指手套",
  babyApron: "婴儿围嘴",
  shoes: "鞋子",
  watch: "手表",
  wallet: "钱包",
  hat: "帽子",
  belt: "皮带",
  men: "男装",
  child: "童装",
  toys: "玩具和婴儿用品",
  cosmetic: "化妆品",
  home: "家具和厨房",
  sport: "体育和旅行",
  stationery: "书籍、文具和艺术",
  noProduct: "目前还没有产品！",
  digitalBT: "拥有最佳体验",
  digitalBD:
    "我们提供最受欢迎品牌的最佳体验。 提供 18 个月的保修，您可以对自己的选择充满信心。",
  stationeryBT: "选择多样性",
  stationeryBD: "在美丽和高效方面，我们专门提供美丽和高质量的文具和办公套装。",
  toyBT: "娱乐和创造力的增长",
  toyBD: "具有最佳质量和抗过敏性的产品，创造快乐时光。",
  houseBT: "现代家居",
  houseBD:
    "在家中享受与亲人一起的满足和平静时光，产品按照最新和突出的风格设计。",
  fashionBT: "美丽和舒适",
  fashionBD:
    "您将以合理的价格找到最好的面料、最热门的设计和最流行的品牌。 让自己看起来最好，脱颖而出。",
  beautyBT: "突显您的美丽",
  beautyBD:
    "对于所有肤质的人来说，都可以找到他们需要的产品，不过敏。 世界卫生组织批准，世界著名品牌的产品。",
  deliver: "海外包邮",
  cash: "正品保证",
  support: "全程追踪",
  warrantyBenefit: "售后无忧",
  return: "商品退货保证",
  offers: "特价优惠",
  recommend: "推荐商品",
  newest: "最新商品",
  best: "最畅销商品",
  seeAll: "查看全部",
  specialSale: "特卖",
  seeAllNewProducts: "查看所有新产品",
  seeAllBestProducts: "查看所有畅销产品",
  digitalCategoryTitle: "数码产品",
  digitalCategoryDescription: "我们以最具竞争力的价格提供最新产品",
  fashionCategoryTitle: "时尚和服装",
  fashionCategoryDescription: "以最合理的价格提供最受欢迎的品牌",
  beautyCategoryTitle: "化妆品",
  beautyCategoryDescription: "适用于所有肤质的抗过敏产品，来自知名品牌",
  sportCategoryTitle: "体育和旅行",
  sportCategoryDescription: "高质量和耐用的产品",
  houseCategoryTitle: "家具和厨房",
  houseCategoryDescription: "符合当今设计的产品",
  toyCategoryTitle: "玩具和婴儿用品",
  toyCategoryDescription: "娱乐和创造力的结合",
  stationeryCategoryTitle: "书籍、文具和艺术",
  stationeryCategoryDescription: "选择多样性",
  seeAllProducts: "查看所有产品",
  expireDiscount: "此产品的折扣已到期。",
  days: "天",
  hours: "小时",
  mins: "分钟",
  seconds: "秒",
  larisaTitle: "Larissa 家具",
  larisaDescription:
    "Larissa 独特设计的漂亮沙发可以为您的接待装饰带来独特的美丽。",
  byNow: "添加到购物车",
  see: "查看产品",
  romanoTitle: "Romano 家具",
  romanoDescription:
    "Romano 沙发具有令人印象深刻的美感，可以增加您家居装饰的魅力。",
  popularBrands: "热门品牌",
  Map: "地图",
  aboutUs: "关于我们",
  contactUs: "联系我们",
  saleInshop: "实体店销售",
  careerOpportunities: "职业机会",
  customerServices: "客户服务",
  commonQuestions: "常见问题",
  returnProcedures: "退货流程",
  privacy: "隐私",
  shoppingGuide: "购物指南",
  howToPlaceAnOrder: "如何下订单？",
  orderSubmissionProcedure: "订单提交流程",
  paymentMethods: "支付方式",
  beWithUs: "与我们一起！",
  emailRegister: "通过电子邮件注册，随时了解最新折扣",
  yourEmail: "请输入您的电子邮件",
  register: "注册",
  copyRight: "2023 LingLink Ltd. 保留所有权利",
  sort: "基于排序：",
  all: "全部",
  newestProducts: "最新",
  cheapest: "最便宜",
  popular: "最受欢迎",
  expensive: "最昂贵",
  details: "产品详情",
  back_camera: "主摄像头",
  battery: "电池",
  front_camera: "前置摄像头",
  operating_system: "操作系统",
  processor: "CPU",
  ram: "RAM",
  screen: "屏幕",
  resolution: "分辨率",
  display_type: "显示类型",
  viewing_angle: "可视角度",
  response_time: "响应时间",
  refresh_rate: "刷新率",
  panel_technology: "面板技术",
  input_output_types: "输入/输出类型",
  true: "是",
  false: "否",
  bluetooth: "蓝牙",
  frequency_response: "频率响应",
  sound_isolating: "隔音",
  microphone: "麦克风",
  noise_cancelling: "降噪",
  wireless: "无线",
  wireless_standby_time: "待机模式下的电池寿命",
  connectionType: "连接类型",
  connectors: "连接器",
  multimedia_keys: "多媒体按键",
  number_of_buttons: "按钮数量（包括滚轮）",
  width: "宽度",
  height: "高度",
  depth: "深度",
  curved: "曲面",
  built_in_speakers: "内置扬声器",
  mechanical_keys: "机械按键",
  backlit_keys: "背光按键",
  ergonomic_design: "人体工学设计",
  designed_for_gaming: "专为游戏而设计",
  graphic: "图形",
  ports: "端口",
  ssd: "固态硬盘",
  warranty: "保修",
  addToCart: "添加到购物车",
  stars: "星级",
  price: "产品价格",
  similarProducts: "您可能感兴趣的产品",
  cart: "购物车",
  hi: "您好！",
  loginExplanation:
    "除了注册外，您还可以使用测试帐户信息（电子邮件：test@info.com - 密码：123456）测试模板。",
  testAccount: "测试帐户",
  userName: "用户名",
  password: "密码",
  email: "电子邮件",
  enterYourUserName: "请输入您的用户名",
  enterYourPassword: "请输入您的密码",
  enterYourEmail: "请输入您的电子邮件",
  doHaveAnAccount: "还没有帐户？",
  alreadyHaveAnAccount: "已经有一个帐户了？",
  signUp: "注册",
  Invalid_email_or_password: "无效的电子邮件或密码",
  Email_already_exists: "电子邮件已存在",
  aboutLongText:
    "ZShop 商店模板是为了整合我的前端知识而编写的，使用了 Nextjs、Typescript、React、Tailwindcss 等技术作为我的作品集。我要感谢 CafeDX 和工程师 Seyed Mehdi Hasanpour 的支持。",
  aboutEnjoy: "我希望您喜欢观看和使用这个模板。",
  myName: "Zahra Mirzaei",
  cafeDX: "谢谢您",
  productAddedToCartMsg: "产品已成功添加到购物车",
  productRemovedFromCartMsg: "产品已成功从购物车中移除",
  productAddedToFavoritesMsg: "产品已成功添加到心愿清单",
  productRemovedFromFavoritesMsg: "产品已成功从心愿清单中移除",
  productAddedToCart: "产品已添加到购物车",
  productRemovedFromCart: "产品已从购物车中移除",
  productAddedToFavorites: "产品已添加到心愿清单",
  productRemovedFromFavorites: "产品已从心愿清单中移除",
  productNotFound: "未找到产品",
  productNotFoundMsg: "未找到产品",
  productNotAvailable: "产品不可用",
  productNotAvailableMsg: "产品不可用",
  productOutOfStock: "产品缺货",
  productOutOfStockMsg: "产品缺货",
  productNotEnoughStock: "产品库存不足",
  productNotEnoughStockMsg: "产品库存不足",
  productNotEnoughStockQuantity: "产品库存不足数量",
  productNotEnoughStockQuantityMsg: "产品库存不足数量",
  productNotEnoughStockQuantityMax: "产品库存不足数量最大",
  productNotEnoughStockQuantityMaxMsg: "产品库存不足数量最大",
  productNotEnoughStockQuantityMin: "产品库存不足数量最小",
  productNotEnoughStockQuantityMinMsg: "产品库存不足数量最小",
  productNotEnoughStockQuantityMaxMin: "产品库存不足数量最大最小",
  productNotEnoughStockQuantityMaxMinMsg: "产品库存不足数量最大最小",
  userProfile: "用户档案",
  addressManagement: "地址管理",
  orderList: "订单列表",
  orderNumber: "订单号",
  orderDate: "订单日期",
  orderStatus: "订单状态",
  orderAmount: "订单金额",
  orderDetails: "订单详情",
  orderDetailsTitle: "订单详情",
  totalWeight: "总重量",
  shippingCost: "运费",
  paymentMethod: "支付方式",
  paymentTime: "支付时间",
  shippingInfo: "发件人信息",
  senderName: "发件人姓名(选填)",
  senderContact: "发件人联系方式(选填)",
  senderAddress: "发件人地址(选填)",
  shippingName: "收件人姓名(必填)",
  shippingID: "收件人身份证号码(必填)",
  shippingContact: "收件人联系方式(必填)",
  shippingAddress: "收件人地址(必填)",
  shippingCity: "收件人城市(选填)",
  shippingCountry: "收件人国家(选填)",
  shippingNamePlaceholder: "请输入收件人姓名",
  shippingIDPlaceholder: "请输入收件人身份证号码",
  shippingContactPlaceholder: "请输入收件人联系方式",
  shippingAddressPlaceholder: "请输入收件人地址",
  shippingCityPlaceholder: "请输入收件人城市",
  shippingCountryPlaceholder: "请输入收件人国家",
  shippingCompany: "运输公司",
  shippingId: "运输编号",
  trackingNumber: "追踪编号",
  deleting: "删除中",
  deleteOrder: "删除订单",
  orderPaid: "订单已支付",
  modifyOrder: "修改订单",
  userEmail: "用户电子邮件",
  userGroup: "用户组",

  addAddress: "添加地址",
  deleteAddress: "删除地址",
  addressesManagement: "地址管理",
  setDefaultAddress: "设置为默认地址",
  defaultAddress: "默认地址",
  editAddress: "编辑地址",
  cancel: "取消",
  clearCart: "清空购物车",

  allOrder: "全部订单",
  notPayOrder: "未支付订单",
  paidOrder: "已支付订单",
  completedOrder: "已完成订单",
  cancelledOrder: "取消订单",
  noOrder: "目前这类没有订单!",

  memberLogin: "会员登录",
  memberRegister: "会员注册",
  memberLogout: "会员登出",
  emailPlaceholder: "请输入您的电子邮件",
  userNamePlaceholder: "请输入您的用户名",
  confirmPassword: "确认密码",
  passwordPlaceholder: "请输入您的密码",
  confirmPasswordPlaceholder: "请再次输入您的密码",
  toLoginPage: "已有账号？点击登陆",
  toRegisterPage: "没有账号？点击注册",

  shipInfo: "发货信息",
  chooseAddress: "选择地址",
  selectShipAddress: "选择发货地址",
  senderInfo: "收货人信息",
  senderNamePlaceholder: "请输入收货人姓名",
  senderContactPlaceholder: "请输入收货人联系方式",
  senderAddressPlaceholder: "请输入收货人地址",
  back: "返回",
  next: "下一步",
  submit: "提交",
  close: "关闭",
  new: "新建",
  goShippingManagement: "前往地址管理",

  loginSuccess: "登陆成功",
  logoutSuccess: "登出成功",
  signUpSuccess: "注册成功",
  products: "产品",
  noCart: "目前购物车是空的",
  totalProductAmount: "产品总金额",

  unpaid: "待付款",
  paid: "已付款",
  completed: "已发货",
  cancelled: "已取消",

  newProducts: "最新产品",
};
export default cn;

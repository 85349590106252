import Header from "../Header";
import Footer from "../Footer";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { fetchShopRequest } from "../../redux/ducks/shop";
import store from "../../redux/configure-store";
import { useSelector } from "react-redux";
import Loading from "../Loading";
import { Helmet } from "react-helmet";
import ContactFloatingButton from '../ContactFloatingButton';

const Layout = () => {
    const loading = useSelector((state) => state.loading);
    const { shop } = useSelector((state) => state.shop);

    useEffect(() => {
        store.dispatch(fetchShopRequest());
    }, []);

    return (
        <div>
            <Helmet>
                <title>{shop?.shop_name}</title>
                <meta name="description" content={shop?.shop_name} />
                <link rel="icon" href={`${shop?.shop_ico}`} />
                <link rel="apple-touch-icon" href={`${shop?.shop_logo}`} />
            </Helmet>
            {loading ? <Loading isLoading={loading} /> : null}
            <div className="fixed top-0 w-full z-10">
                <Header />
            </div>
            <main className="min-h-without-header pt-32 px-5 xl:px-16 flex flex-col bg-white transition-all duration-300 ease-in-out space-y-4 max-w-8xl mx-auto">
                <Outlet />
            </main>
            <ContactFloatingButton />
            <Footer />
        </div>
    );
};

export default Layout;
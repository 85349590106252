import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const AlertContext = createContext();

function AlertProvider({ children }) {
    const [alert, setAlert] = useState({
        isOpen: false,
        type: '',  // 'success', 'error', 'info'
        message: ''
    });

    const showAlert = (type, message) => {
        setAlert({
            isOpen: true,
            type: type,
            message: message
        });
        setTimeout(() => {
            setAlert({
                ...alert,
                isOpen: false
            });
        }, 2000);
    };

    const hideAlert = () => {
        setAlert({
            ...alert,
            isOpen: false
        });
    };

    return (
        <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
            {children}
        </AlertContext.Provider>
    );
}

AlertProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { AlertContext, AlertProvider };

// Redux/ducks/cart.js
export const SET_CART = "SET_CART";
export const FETCH_CART = "FETCH_CART";

export const setCart = (cart) => ({
  type: SET_CART,
  payload: cart,
});

export const fetchCart = () => ({
  type: FETCH_CART,
});

const initialState = {};

export default function cartRedux(state = initialState, action) {
  switch (action.type) {
    case SET_CART:
      return action.payload;
    default:
      return state;
  }
}

// src/hooks/useFetchProducts.js

import { useState, useEffect } from 'react';

function useFetchProducts(apiMethod, selectedRadioBtn, id = '') {
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        let isMounted = true;

        async function fetchData() {
            try {
                const response = await apiMethod(id, selectedRadioBtn, currentPage);
                if (isMounted) {
                    setProducts(response.data.products.data);
                    setTotalPages(response.data.products.last_page);
                }
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id, selectedRadioBtn, currentPage, apiMethod]);

    return {
        products,
        totalPages,
        currentPage,
        setCurrentPage
    };
}

export default useFetchProducts;

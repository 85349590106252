import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getArticle } from '../api/article'; // 假设这是您的 API 函数

function ArticlePage() {
    const { id } = useParams();
    const [article, setArticle] = useState(null)


    useEffect(() => {
        if (id) {
            getArticle(id)
                .then(response => {
                    setArticle(response.data.article);

                })
                .catch(error => {
                    console.error("Error fetching article:", error);

                });
        }
    }, [id]);

    if (!article) return <div>Article not found.</div>;

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">{article.title}</h1>
            <div
                dangerouslySetInnerHTML={{ __html: article.longText }}
                className="article-content prose prose-lg" // Tailwind CSS 的排版样式类
            />
            {/* 其他文章内容 */}
        </div>
    );
}

export default ArticlePage;

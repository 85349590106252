
import React, { useEffect, useState } from 'react'
import { getBannerProducts, getUserBannerProducts } from "../api/banners";
import { useParams } from "react-router-dom";
import Pagination from "../components/Ui/Pagination";
import ProductList from '../components/ProductList'
import ProductSort from '../components/Ui/ProductSort';
import { useSelector } from 'react-redux';

const BannerProductPage = () => {
    const routeParams = useParams();
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedRadioBtn, setSelectedRadioBtn] = useState("recommend");
    const [banner, setBanner] = useState([]);
    const user = useSelector(state => state.user);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const onChangeHandler = (e) => {
        setSelectedRadioBtn(e.currentTarget.id);
    }

    useEffect(() => {
        const fetchCategoryProducts = async () => {
            try {
                const Response = user?.name ? await getUserBannerProducts(routeParams?.id, selectedRadioBtn, currentPage) : await getBannerProducts(routeParams.id, selectedRadioBtn, currentPage);
                setProducts(Response?.data?.products.data);
                setTotalPages(Response?.data?.total_pages)
                setBanner(Response?.data.banner)
            } catch (error) {
                console.error("Error fetching category products:", error);
                // You can also set an error state here if needed
            }
        };
        fetchCategoryProducts();
    }, [routeParams.id, selectedRadioBtn, currentPage]);

    return (
        <div>
            <div
                className={`relative w-[100%] aspect-ratio bg-cover bg-center bg-no-repeat cursor-pointer`}
                style={{
                    backgroundImage: `url(${banner?.avatar})`,
                    backgroundSize: 'cover' // 修改为 'cover'
                }}
            >
            </div>
            <ProductSort
                selectedBtn={selectedRadioBtn}
                onChangeSelectedBtn={onChangeHandler} />
            <ProductList products={products} />
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    )
}

export default BannerProductPage;
import { request } from "../utils";
import { ARTICLE_API } from "./constants";

export const getArticleList = () => {
  return request.get(ARTICLE_API);
};

export const getArticle = (id) => {
  return request.get(`${ARTICLE_API}/${id}`);
};

import { request } from "../utils";
import { USER_API } from "./constants";

export const userLogin = (data) => {
  return request.post(`${USER_API}/login`, data);
};

export const userLogout = (data) => {
  return request.post(`${USER_API}/logout`, data);
};

export const userSignUp = (data) => {
  return request.post(`${USER_API}/register`, data);
};

export const checkLoginStatus = () => {
  return request.get(`${USER_API}/check-login-status`);
};

export const requestPasswordReset = (data) => {
  return request.post(`${USER_API}/password/reset-request`, data);
};

export const resetPassword = (data) => {
  return request.post(`${USER_API}/password/reset`, data);
};

// The email field must be a valid email address and is required.
// The username field is required, but it does not have any specific rules for its format or length.
// The password field must be at least 6 characters long and at most 20 characters long, and it must match the passwordRules regular expression, which enforces a specific password format.
// The acceptedTos field must be set to true in order for the form to be valid.
// The confirmPassword field is currently commented out, so it is not part of the schema. This field would be used to compare the password field to ensure that they match.

import * as yup from "yup";

const passwordRules = /^[a-zA-Z0-9]{6,20}$/;

export const signUpSchema = yup.object().shape({
  email: yup.string().email("请输入正确的邮箱").required("请输入您的电子邮箱"),
  name: yup.string().required("请输入您的用户名"),
  password: yup
    .string()
    .min(8, "密码至少需要8个字符")
    .max(20, "密码最多只能有20个字符")
    .matches(passwordRules, {
      message: "请创建一个更强的密码",
    })
    .required("请输入您的密码"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "两次输入的密码不一致")
    .required("请确认您的密码"),
  acceptTerms: yup
    .boolean()
    .required("必须接受条款")
    .oneOf([true], "您必须接受服务条款和隐私政策"),
});

export const signUpSchemaEn = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter an email"),
  name: yup.string().required("Please enter a username"),
  password: yup
    .string()
    .min(8)
    .max(20)
    .matches(passwordRules, {
      message: "Please create a stronger password",
    })
    .required("Please enter a password"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please enter a confirmPassword"),
});

import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

const CustomInput = ({ label, type, name, placeholder, children, className, ...props }) => {
    const [field, meta] = useField(name);

    return (
        <div className="mb-4">
            {label && (
                <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                    {label}
                </label>
            )}
            {type !== 'select' && type !== 'textarea' ? (
                <input
                    {...field}
                    type={type}
                    id={name}
                    placeholder={placeholder}
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${meta.error && meta.touched ? 'border-red-500' : ''} ${className}`}
                    {...props}
                />
            ) : type === 'textarea' ? (
                <textarea
                    {...field}
                    id={name}
                    placeholder={placeholder}
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${meta.error && meta.touched ? 'border-red-500' : ''} ${className}`}
                    {...props}
                />
            ) : (
                <select
                    {...field}
                    id={name}
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${meta.error && meta.touched ? 'border-red-500' : ''} ${className}`}
                    {...props}
                >
                    {children}
                </select>
            )}
            {meta.touched && meta.error ? (
                <div className="text-sm text-red-500">{meta.error}</div>
            ) : null}
        </div>
    );
};

CustomInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    children: PropTypes.node, // 新增
    className: PropTypes.string,
};

export default CustomInput;

import { request } from "../utils";
import { BRAND_API, USER_BRAND_API } from "./constants";

export const getBrandList = () => {
  return request.get(BRAND_API);
};

export const getBrandProducts = (id, sort = "recommend", page = 1) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${BRAND_API}/${id}${queryParams}`);
};

export const getUserBrandProducts = (id, sort = "recommend", page = 1) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${USER_BRAND_API}/${id}${queryParams}`);
};

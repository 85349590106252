
import React, { useEffect, useState } from 'react'
import { getSearchProducts, getUserSearchProducts } from "../api/product";
import Pagination from "../components/Ui/Pagination";
import ProductList from '../components/ProductList'
import { useSelector } from 'react-redux';
import ProductSort from '../components/Ui/ProductSort';

const SearchPage = () => {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const searchParams = useSelector((state) => state.search);
    const [selectedRadioBtn, setSelectedRadioBtn] = useState("recommend");
    const user = useSelector(state => state.user);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const onChangeHandler = (e) => {
        setSelectedRadioBtn(e.currentTarget?.id);
    }

    useEffect(() => {

        const fetchSearchProducts = async () => {
            try {
                const Response = user?.name ? await getUserSearchProducts(searchParams, selectedRadioBtn, currentPage) : await getSearchProducts(searchParams, selectedRadioBtn, currentPage);
                setProducts(Response.data.products.data);
                setTotalPages(Response.data.products.last_page)
            } catch (error) {
                console.error("Error fetching search products:", error);
            }
        };

        fetchSearchProducts();
    }, [searchParams, selectedRadioBtn, currentPage]);

    return (
        <div>
            <div className="px-4 py-12 text-center sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">商品搜索</h1>
                <p className="mx-auto mt-3 max-w-xl text-base text-gray-500 sm:mt-4 sm:text-lg">
                    从新西兰直邮到中国的优质商品，为您带来跨境购物的便捷与信赖。
                </p>
            </div>
            <ProductSort
                selectedBtn={selectedRadioBtn}
                onChangeSelectedBtn={onChangeHandler} />
            <ProductList products={products} />
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    )
}

export default SearchPage;
import React from 'react';
import PropTypes from "prop-types";

const Loading = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-palette-primary"></div>
        </div>
    );
};

Loading.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

export default Loading;
import React, { useState, useEffect, useContext } from 'react'
import { Tab } from '@headlessui/react'
import { useParams } from 'react-router-dom';
import { getProduct, getUserProduct } from '../api/product';
import ProductPrice from '../components/Ui/ProductPrice';
import { FiShare2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { addFavorite, removeFavorite } from "../api/favorite";
import { setFavorite } from "../redux/ducks/favorite";
import store from '../redux/configure-store';
import { addToCart } from '../api/cart';
import { setCart } from '../redux/ducks/cart';
import { AlertContext } from '../components/Alerts/AlertsContext';
import { Disclosure } from '@headlessui/react'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import { combinedProductName } from "../utils/calculate";
import {
    MinusIcon,
    PlusIcon,
} from '@heroicons/react/24/outline'
import {
    RiHeartFill,
    RiHeartAddLine,
} from "react-icons/ri";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProductDetail = () => {
    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState({});
    const [selectedVariant, setSelectedVariant] = useState("")
    const routeParams = useParams();
    const favoriteItems = useSelector((state) => state.favorite);
    const isInFavorite = favoriteItems?.length ? favoriteItems?.find((item) => item?.product_id === product?.id) : null;
    const FavoriteIcon = isInFavorite ? RiHeartFill : RiHeartAddLine;
    const [loading, setLoading] = useState(false);
    const { showAlert } = useContext(AlertContext);
    const user = useSelector(state => state.user);

    const handleAddToCart = async (event) => {
        event.preventDefault();
        if (product?.virtual_inventory <= 0) {
            showAlert('info', '已售罄，没有添加购物车');
        }
        try {
            const response = await addToCart({ product_id: product?.id, quantity: quantity, variant_id: selectedVariant?.id });
            store.dispatch(setCart(response.data.carts));
            showAlert('success', '添加购物车成功');
            setQuantity(1);
        } catch (error) {
            if (error === "Unauthenticated.") {
                showAlert('info', '请先登录');
            }
        }
    };

    const handleIncreaseQuantity = (event) => {
        event.preventDefault();
        setQuantity(quantity + 1);
    };

    const handleDecreaseQuantity = () => {
        event.preventDefault();
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleAddToFavorite = async () => {
        event.preventDefault();
        if (isInFavorite) {
            const response = await removeFavorite({ product_id: product?.id });
            await store.dispatch(
                setFavorite(response?.data?.favorites)
            );
            showAlert('success', '已从心愿清单移除');
        } else {
            try {
                const response = await addFavorite({ product_id: product?.id });
                await store.dispatch(
                    setFavorite(response?.data?.favorites)
                );
                showAlert('success', '已添加到心愿清单');
            } catch (error) {
                if (error === "Unauthenticated.") {
                    showAlert('info', '请先登录');
                }
            }
        }
    };

    const handleShareProduct = () => {
        event.preventDefault();
        //复制url链接
        navigator.clipboard.writeText(window.location.href);
        showAlert('success', '产品链接已复制');
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                setLoading(true);
                const response = user?.name ? await getUserProduct(routeParams?.id) : await getProduct(routeParams?.id);

                setProduct(() => {
                    const newProduct = response?.data?.product;
                    if (newProduct?.product_variants?.length) {
                        setSelectedVariant(newProduct.product_variants[0]);
                    }
                    return newProduct;
                });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };

        fetchProduct();
    }, [routeParams?.id]);

    useEffect(() => {
        // 在页面加载后将滚动位置设置为顶部
        window.scrollTo(0, 0);
    }, []); // 使用空的依赖数组确保只在组件挂载时执行一次

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen" >

            </div>
        );
    }

    return (
        <div className="mx-auto max-w-7xl sm:px-6 sm:pt-16 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
                {/* Product */}
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                    {/* Image gallery */}
                    <Tab.Group as="div" className="flex flex-col-reverse">
                        {/* Image selector */}
                        <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                            <Tab.List className="grid grid-cols-4 gap-6">
                                {product?.images
                                    ?.sort((a, b) => (Number(product.avatar) === Number(a?.id) ? -1 : product.avatar === b?.id ? 1 : 0))
                                    .map((image) => (
                                        <Tab
                                            key={image?.id}
                                            className="relative flex w-24 h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span className="sr-only">{image?.name}</span>
                                                    <span className="absolute inset-0 overflow-hidden rounded-md p-4">
                                                        <img src={image.path} alt="" className="h-full w-full object-cover object-center" />
                                                    </span>
                                                    <span
                                                        className={classNames(
                                                            selected ? 'ring-indigo-500' : 'ring-transparent',
                                                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </>
                                            )}
                                        </Tab>
                                    ))}
                            </Tab.List>
                        </div>
                        <Tab.Panels className="aspect-w-1 aspect-h-1 w-full relative">
                            {product?.images?.map((image) => (
                                <Tab.Panel key={image?.id} className="flex justify-center items-center">
                                    <img
                                        src={image?.path}
                                        alt={image?.alt}
                                        className="max-h-[300px] w-auto object-cover object-center sm:max-h-[400px] lg:max-h-[500px]"
                                    />
                                </Tab.Panel>
                            ))}
                            {Number(product?.virtual_inventory) === 0 ? (
                                <div className="absolute top-1 right-1 sm:top-2 sm:right-2">
                                    <div className="bg-gray-700 text-white text-m sm:text-xl font-bold px-2 sm:px-3 py-1 rounded-full opacity-90">
                                        售罄
                                    </div>
                                </div>
                            ) : Number(product?.discount) > 0 && ((Number(product?.member_price) >= Number(product?.discount) || (product?.member_price === 0) || (product?.member_price === null))) ? (
                                <div className="absolute top-1 right-1 sm:top-2 sm:right-2">
                                    <div className="bg-red-400 text-white text-m sm:text-xl font-bold px-2 sm:px-3 py-1 rounded-full opacity-90">
                                        特价
                                    </div>
                                </div>
                            ) : null}
                            {product?.is_free_shipping ? (
                                <div className="absolute top-1 left-1 sm:top-2 sm:left-2">
                                    <div className="bg-blue-500 text-white text-m sm:text-xl font-bold px-2 sm:px-3 py-1 rounded-full opacity-90">
                                        包邮
                                    </div>
                                </div>
                            ) : null}
                        </Tab.Panels>
                    </Tab.Group>

                    {/* Product info */}
                    <div className="mt-4 px-4 sm:px-0 sm:mt-6 lg:mt-0">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{combinedProductName(product)}</h1>

                        <ProductPrice
                            price={selectedVariant ? selectedVariant.price : product.price}
                            discount={selectedVariant ? selectedVariant.discount : product.discount}
                            member_price={selectedVariant ? selectedVariant.member_price : product.member_price}
                            isLargeSize="lg"
                        />

                        <div className="product-details text-gray-700">

                            <div className="product-description my-4">
                                <span>{product?.description}</span>
                            </div>

                            <div className="product-inventory mb-4">
                                <span className="font-semibold">库存:</span>
                                <span className="ml-2">
                                    {selectedVariant ? selectedVariant.virtual_inventory : product.virtual_inventory > 99 ? 'In Stock' : `Only ${selectedVariant ? selectedVariant.virtual_inventory : product.virtual_inventory} Left`}
                                </span>
                            </div>

                            <div className="product-weight mb-4">
                                <span className="font-semibold">重量:</span>
                                <span className="ml-2">{selectedVariant ? selectedVariant.weight : product.weight}
                                    &nbsp;g</span>
                            </div>

                            <div className="product-shipping mb-4">
                                <span className="font-semibold">包邮:</span>
                                <span className="ml-2">
                                    {product.is_free_shipping ? "是" : "否"}
                                </span>
                            </div>
                        </div>


                        <form className="mt-2">
                            <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-4">
                                {product?.product_variants && product?.product_variants?.length > 0 && (
                                    <>
                                        <h3 className="text-xl font-semibold mb-2 sm:mb-0 py-2">特殊分类:</h3>
                                        <div className="flex overflow-x-auto pb-2">
                                            <div className="flex flex-nowrap">
                                                {product?.product_variants?.map((variant) => (
                                                    <div key={variant?.id} className="flex-shrink-0">
                                                        <span className={`cursor-pointer px-4 py-2 m-1 rounded-lg text-white inline-block ${selectedVariant && selectedVariant?.id === variant?.id ? 'bg-gray-700' : 'bg-gray-300 text-black'}`} onClick={() => setSelectedVariant(variant)}>
                                                            {variant?.name}
                                                        </span>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="flex items-center mb-4 mt-4">
                                <button
                                    className="bg-gray-200 px-2 py-1 rounded-lg mr-2"
                                    onClick={handleDecreaseQuantity}
                                >
                                    -
                                </button>
                                <span className="text-lg">{quantity}</span>
                                <button
                                    className="bg-gray-200 px-2 py-1 rounded-lg ml-2"
                                    onClick={handleIncreaseQuantity}
                                >
                                    +
                                </button>
                            </div>
                            <div className="mt-10 flex flex-col sm:flex-row sm:space-x-2">
                                {product?.virtual_inventory > 0 ? <button
                                    type="submit"
                                    onClick={handleAddToCart}
                                    className="flex flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 mb-2 sm:mb-0"
                                >
                                    <ShoppingCartIcon
                                        className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500 white"
                                        aria-hidden="true"
                                        style={{
                                            fill: 'white',
                                        }}
                                    />添加购物车
                                </button> : null}
                                <button
                                    className="flex flex-1 items-center justify-center bg-indigo-500 px-4 py-2 rounded-lg text-white hover:bg-indigo-600 mb-2 sm:mb-0 whitespace-nowrap"
                                    onClick={handleAddToFavorite}
                                >
                                    <FavoriteIcon
                                        className="mr-2"
                                        style={{
                                            fontSize: "1.2rem",
                                            fill: `${isInFavorite ? "#ee384e" : "currentColor"}`,
                                        }}
                                    />
                                    {isInFavorite ? "移除心愿清单" : "添加心愿清单"}
                                </button>
                                <button
                                    className="flex items-center justify-center bg-gray-200 px-4 py-2 rounded-lg hover:bg-gray-300 whitespace-nowrap text-sm md:text-base"
                                    onClick={handleShareProduct}
                                >
                                    <FiShare2 className="mr-2" />分享产品链接
                                </button>
                            </div>

                        </form>

                        <section aria-labelledby="details-heading" className="mt-12">
                            <h2 id="details-heading" className="sr-only">
                                Additional details
                            </h2>

                            <div className="divide-y divide-gray-200 border-t">
                                <Disclosure as="div">
                                    {({ open }) => (
                                        <>
                                            <h3>
                                                <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                                    <span
                                                        className={classNames(
                                                            open ? 'text-indigo-600' : 'text-gray-900',
                                                            'text-sm font-medium'
                                                        )}
                                                    >
                                                        产品成分
                                                    </span>
                                                    <span className="ml-6 flex items-center">
                                                        {open ? (
                                                            <MinusIcon
                                                                className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            <PlusIcon
                                                                className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </h3>
                                            <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                                                <div>{product.composition}</div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <Disclosure as="div">
                                    {({ open }) => (
                                        <>
                                            <h3>
                                                <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                                    <span
                                                        className={classNames(
                                                            open ? 'text-indigo-600' : 'text-gray-900',
                                                            'text-sm font-medium'
                                                        )}
                                                    >
                                                        使用说明
                                                    </span>
                                                    <span className="ml-6 flex items-center">
                                                        {open ? (
                                                            <MinusIcon
                                                                className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            <PlusIcon
                                                                className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </h3>
                                            <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                                                <div>{product.usage_instructions}</div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </div>
                        </section>
                    </div>
                </div>
                {/* Product Description Section */}
                {product?.product_details ? <div className="mt-16">
                    <div className="grid">
                        {product?.product_details ? (
                            <div className="mb-4 col-span-2 flex items-center mx-auto w-full">
                                <div
                                    className="w-full max-w-full text-center"
                                    dangerouslySetInnerHTML={{ __html: product.product_details }}
                                />
                            </div>
                        ) : null}
                    </div>
                </div> : null}
            </div>
        </div>
    )
}

export default ProductDetail
import React from "react";
import { NavLink } from "react-router-dom";
// import { useLanguage } from "../../hooks/useLanguage";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";


const Slide = ({ avatar, link_url, id }) => {
  // const { t } = useLanguage();
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`relative w-[100%] aspect-ratio bg-cover bg-center bg-no-repeat cursor-pointer`}
        style={{
          backgroundImage: `url(${avatar})`,
          backgroundSize: 'cover' // 修改为 'cover'
        }}
        onClick={() => navigate(link_url ? link_url : `/banner-products/${id}`)}
      >
        <NavLink className="block" to={link_url ? link_url : `/banner-products/${id}`}>

        </NavLink>
      </div>
    </>
  );
};

Slide.propTypes = {
  avatar: PropTypes.string.isRequired,
  link_url: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default Slide;

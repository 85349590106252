import React, { useContext, useEffect } from 'react'
import { LoginIn } from "../utils/login"
import { useNavigate, Link } from "react-router-dom";
import { AlertContext } from '../components/Alerts/AlertsContext';
import { getCartList } from "../api/cart";
import { getFavorites } from '../api/favorite';
import store from '../redux/configure-store';
import { setCart } from '../redux/ducks/cart';
import { setFavorite } from '../redux/ducks/favorite';
import CustomInput from '../components/CustomInput';
import { Formik, Form } from "formik";
import { loginSchema } from "../schemas/sign-in";
import { useSelector } from "react-redux";

const SignIn = () => {
    const navigate = useNavigate();
    const { showAlert } = useContext(AlertContext);
    const user = useSelector((state) => state.user);
    const preLoginPath = localStorage.getItem('preLoginPath');

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await LoginIn(values);
            const cartList = await getCartList();
            store.dispatch(setCart(cartList.data.carts));
            const favorites = await getFavorites();
            store.dispatch(setFavorite(favorites.data.favorites.data));
            showAlert('success', '登陆成功');
            if (preLoginPath) {
                navigate(preLoginPath);
                localStorage.removeItem('preLoginPath');
            } else {
                navigate('/'); // 或你的默认重定向路径
            }
        } catch (error) {
            showAlert('error', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (user?.name) {
            navigate("/");
        }
    }, [user?.name]);

    return (
        <div className="flex min-h-without-header flex-1 flex-col justify-center px-6 pt-2 pb-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    登录到您的帐户
                </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={loginSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="space-y-6">
                                <CustomInput
                                    label="电子邮件地址"
                                    name="email"
                                    type="email"
                                    placeholder="请输入您的电子邮件"
                                />
                                <CustomInput
                                    label="密码"
                                    name="password"
                                    type="password"
                                    placeholder="请输入您的密码"
                                />
                                <div className="text-right">
                                    <Link to="/forgot-password" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                        忘记密码？
                                    </Link>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {isSubmitting ? '登录中...' : '登录'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div className="mt-6 text-center">
                        <Link to="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                            非会员? 去注册
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn;

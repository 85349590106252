// api/constants.js
export const BANNER_API = "/api/banner";
export const USER_BANNER_API = "/api/user/banner";
export const BRAND_API = "/api/brand";
export const USER_BRAND_API = "/api/user/brand";
export const CART_API = "/api/cart";
export const CATEGORY_API = "/api/category";
export const USER_CATEGORY_API = "/api/user/category";
export const FAVORITES_API = "/api/favorites";
export const USER_API = "/api";
export const ORDER_API = "/api/order";
export const PRODUCT_API = "/api/product";
export const USER_PRODUCT_API = "/api/user/product";
export const SHIPPING_ADDRESS_API = "/api/shipping-address";
export const SHOP_API = "/api/shop";
export const ARTICLE_API = "/api/article";
export const PAYMENT_API = "/api/payment";

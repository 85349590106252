import { request } from "../utils";
import { ORDER_API } from "./constants";

export const createOrder = (
  cartItems,
  shippingInfo,
  totalWeight,
  shippingCost,
  totalAmount,
  totalQuantity
) => {
  const data = {
    cartItems: cartItems,
    shippingInfo: shippingInfo,
    totalWeight: totalWeight,
    shippingCost: shippingCost,
    totalAmount: totalAmount,
    totalQuantity: totalQuantity,
  };
  return request.post(`${ORDER_API}/create`, data);
};

export const getUserAllOrders = (sort, page) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${ORDER_API}s/${queryParams}`);
};

export const getOrderById = (orderId) => {
  return request.get(`${ORDER_API}/${orderId}`);
};

export const deleteOrderById = (orderId) => {
  return request.post(`${ORDER_API}/delete/${orderId}`);
};

const en = {
  mainPage: "Shop Main Page",
  search: "Search",
  login: "Login",
  logout: "Logout",
  favorites: "Favorites",
  delete: "Delete",
  thereAreNoFavorites: "currently, there are no favorite items",
  signIn: "Sign in",
  seeCart: "See Cart",
  product: "Product",
  totalAmount: "Total Amount",
  totalQuantity: "Total Quantity",
  payableAmount: "Payable Amount",
  loginAndOrder: "Login and Order",
  order: "Order",
  orderSummary: "Order Summary",
  cartIsEmpty: "Cart is empty",
  CategoryOfGoods: "Category of Goods",
  categories: "Categories",
  seeAllProduct: "See All product in this category",
  offer: "Offers and Discounts",
  bestSells: "The Best-Sells",
  mainMenu: "Main Menu",
  language: "Language: ",
  cn: "中文 - Cn",
  en: "English - En",
  theme: "Theme: ",
  dark: "Dark",
  light: "Light",
  digital: "Digital",
  laptop: "Laptop and Accessories",
  asus: "Asus",
  apple: "Apple",
  dell: "Dell",
  lenovo: "Lenovo",
  samsung: "Samsung",
  hp: "HP",
  huawei: "HUAWEI",
  acer: "Acer",
  msi: "MSI",
  mobile: "Mobile and Accessories",
  nokia: "Nokia",
  xiaomi: "Xiaomi",
  motorola: "Motorola",
  lg: "LG",
  sony: "Sony",
  computer: "Computer and Accessories",
  monitor: "Monitor",
  mouse: "Mouse",
  keyboard: "Keyboard",
  hard: "Hard, Flash memory and SSD",
  other: "Other",
  tablet: "Tablet",
  powerBank: "Power Bank",
  speaker: "Bluetooth Speaker and wire",
  headphones: "Headphone, Headset and Hands free",
  fashion: "Fashion and Clothes",
  women: "Women's Fashion",
  dress: "Dress",
  skirt: "Skirt",
  jeans: "Jeans",
  pants: "Pants",
  tShirt: "T-shirt",
  scarf: "Scarf",
  shirt: "Shirt",
  tie: "Tie",
  overalls: "Overalls",
  mittens: "Mittens",
  babyApron: "Baby Apron",
  shoes: "Shoes",
  watch: "Watch",
  wallet: "Wallet",
  hat: "Hat",
  belt: "Belt",
  men: "Men's Fashion",
  child: "Child's Fashion",
  toys: "Toys and Baby",
  cosmetic: "Cosmetic",
  home: "Furniture and kitchen",
  sport: "Sport and Trip",
  stationery: "Books, Stationery and Art",
  noProduct:
    "There are no products in this yet! New products will be added soon.",
  digitalBT: "Have the best Experiences",
  digitalBD:
    "We provide the best Experience with the most popular Brands.  With a warranty of 18 months, you can be confident in your choice",
  stationeryBT: "Variety in choice",
  stationeryBD:
    "When it comes to Beauty and Efficiency. We specialize in providing stationery and office sets that are beautiful and high quality.",
  toyBT: "Entertainment and the growth of Creativity",
  toyBD:
    "Products with the best quality and anti-allergy to create happy moments",
  houseBT: "Modern Home",
  houseBD:
    "enjoy beautiful moments of satisfaction and peace with your loved ones at home with products designed according to the most recent and prominent styles.",
  fashionBT: "Beauty and Comfort",
  fashionBD:
    "You will find the best fabrics, the hottest designs, and the most popular brands at reasonable prices. Look your best and stand out among the crowd.",
  beautyBT: "Accentuates your Beauty",
  beautyBD:
    "allergen-free, people with all skin types can find products they need. Approved by the World Health Organization, products from world-renowned brands.",
  deliver: "express delivery",
  cash: "payment on the spot",
  support: "24/7 support",
  warrantyBenefit: "Guarantee the originality",
  return: "commodity-back guarantee",
  offers: "Special Offers",
  recommend: "Recommended Goods",
  newest: "Newest Goods",
  seeAll: "See All",
  specialSale: "Special Sale",
  seeAllNewProducts: "See All New Products",
  digitalCategoryTitle: "Digital Products",
  digitalCategoryDescription:
    "We offer the newest products at the most competitive prices",
  fashionCategoryTitle: "Fashion and Clothes",
  fashionCategoryDescription:
    "The most popular brands with the most reasonable prices",
  beautyCategoryTitle: "Cosmetic Products",
  beautyCategoryDescription:
    "Anti-allergy products suitable for all skin types from reputable brands",
  sportCategoryTitle: "Sport and Trip",
  sportCategoryDescription: "High-quality and durable products",
  houseCategoryTitle: "Furniture and kitchen",
  houseCategoryDescription: "In accordance with the designs of the day",
  toyCategoryTitle: "Toys and Baby",
  toyCategoryDescription: "A combination of entertainment and creativity",
  stationeryCategoryTitle: "Books, Stationery and Art",
  stationeryCategoryDescription: "Variety in choice",
  seeAllProducts: "See All Products",
  expireDiscount: "Discount for this Product was Expire.",
  days: "Days",
  hours: "Hours",
  mins: "Mins",
  seconds: "Seconds",
  larisaTitle: "Larissa Furniture",
  larisaDescription:
    "Larissa beautiful sofa with its private design can give a unique beauty to your reception decoration.",
  byNow: "Add to cart",
  see: "See Product",
  romanoTitle: "Romano furniture",
  romanoDescription:
    "Romano sofa has an impressive beauty and can multiply the charm of your home decoration.",
  popularBrands: "Popular Brands",
  shopMap: "shop Map",
  aboutUs: "About Us",
  contactUs: "Contact Us",
  saleInshop: "Sale In shop",
  careerOpportunities: "Career Opportunities",
  customerServices: "Customer Services",
  commonQuestions: "Common Questions",
  returnProcedures: "Return Procedures",
  privacy: "Privacy",
  shoppingGuide: "Shopping Guide",
  howToPlaceAnOrder: "How To Place An Order?",
  orderSubmissionProcedure: "Order Submission Procedure",
  paymentMethods: "Payment Methods",
  beWithUs: "Be With Us!",
  emailRegister: "Stay up to date with the latest discounts by emailing us",
  yourEmail: "Please Enter Your Email",
  register: "Register",
  sort: "Sort based on: ",
  all: "All",
  newestProducts: "Newest",
  cheapest: "Cheapest",
  popular: "The Most Popular",
  expensive: "The Most Expensive",
  details: "Product Details",
  back_camera: "Main Camera",
  battery: "Battery",
  front_camera: "Front Camera",
  operating_system: "Operating System",
  processor: "CPU",
  ram: "RAM",
  screen: "Screen",

  resolution: "Resolution",
  display_type: "Display Type",
  viewing_angle: "Viewing Angle",
  response_time: "Response Time",
  refresh_rate: "Refresh Rate",
  panel_technology: "Panel Technology",
  input_output_types: "Input/Output Types",

  true: "Yes",
  false: "No",
  bluetooth: "Bluetooth",
  frequency_response: "Frequency Response",
  sound_isolating: "Sound Isolating",
  microphone: "Microphone",
  noise_cancelling: "Noise Cancelling",
  wireless: "Wireless",
  wireless_standby_time: "Battery life in standby mode",
  connectionType: "Connection Type",
  connectors: "Connectors",

  multimedia_keys: "Multimedia Keys",
  number_of_buttons: "Number Of Buttons(Including Scroll Wheel)",
  width: "Width",
  height: "Height",
  depth: "Depth",
  curved: "Curved",
  built_in_speakers: "Built-in Speakers",
  mechanical_keys: "Mechanical Keys",
  backlit_keys: "Backlit Keys",
  ergonomic_design: "Ergonomic Design",
  designed_for_gaming: "Designed For Gaming",

  graphic: "Graphic",
  ports: "Ports",
  ssd: "SSD Hard",
  warranty: "Warranty",
  addToCart: "ADD TO CART",
  stars: "Star",
  price: "Product price",
  similarProducts: "You May Be Interested In",
  cart: "Shopping Cart",
  hi: "Hi!",
  loginExplanation:
    "Besides registering, you can also test the template with test account information(email: test@info.com - Password: 123456)",
  testAccount: "test account",
  userName: "User Name",
  password: "Password",
  email: "Email",
  enterYourUserName: "Please Enter Your UserName",
  enterYourPassword: "Please Enter Your Password",
  enterYourEmail: "Please Enter Your Email",
  doHaveAnAccount: "Don't You Have An Account? ",
  alreadyHaveAnAccount: "Already You Have An Account? ",
  signUp: "Sign Up",
  Invalid_email_or_password: "Invalid email or password",
  Email_already_exists: "Email already exists",
  aboutLongText:
    "The ZShop store template was written to consolidate my front-end knowledge, using technologies such as Nextjs, Typescript, React, Tailwindcss, etc. as a portfolio. I would like to thank CafeDX and engineer Seyed Mehdi Hasanpour for his support.",
  aboutEnjoy: "I hope you enjoy watching and working with this template.",
  myName: "Zahra Mirzaei",
  cafeDX: "Thank you",
  productAddedToCartMsg: "Product added to cart successfully",

  copyRight: "2023 Wetrading. All rights reserved",
  orderDetailsTitle: "Order Details",
  totalWeight: "Total Weight",
  shippingCost: "Shipping Cost",
  paymentMethod: "Payment Method",
  paymentTime: "Payment Time",
  senderName: "Sender's Name",
  senderContact: "Sender's Contact",
  senderAddress: "Sender's Address",
  shippingName: "Shipping Name",
  shippingContact: "Shipping Contact",
  shippingAddress: "Shipping Address",
  shippingCity: "Shipping City",
  shippingCountry: "Shipping Country",
  shippingCompany: "Shipping Company",
  shippingId: "Shipping ID",
  trackingNumber: "Tracking Number",
  deleting: "Deleting",
  deleteOrder: "Delete Order",
  orderPaid: "Order Paid",
  modifyOrder: "Modify Order",
  userEmail: "User Email",
  userGroup: "User Group",

  addAddress: "Add Address",
  deleteAddress: "Delete Address",
  addressesManagement: "Addresses Management",
  setDefaultAddress: "Set Default Address",
  defaultAddress: "Default Address",
  editAddress: "Edit Address",
  cancel: "Cancel",
  clearCart: "Clear Cart",

  allOrder: "All Order",
  notPayOrder: "Not Pay Order",
  paidOrder: "Paid Order",
  completedOrder: "Completed Order",
  cancelledOrder: "Cancelled Order",
  noOrder: "No Order",

  memberLogin: "Member Login",
  memberRegister: "Member Register",
  memberLogout: "Member Logout",
  emailPlaceholder: "Please Enter Your Email",
  userNamePlaceholder: "Please Enter Your UserName",
  confirmPassword: "Confirm Password",
  passwordPlaceholder: "Please Enter Your Password",
  confirmPasswordPlaceholder: "Please Enter Your Password Again",
  toLoginPage: "Already Have An Account? Click To Login",
  toRegisterPage: "Don't Have An Account? Click To Register",

  newProducts: "New Products",
};
export default en;

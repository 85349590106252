// The filter object is empty data
import Toast from "../components/Toast";

export const filterData = (data) => {
  const searchParams = JSON.parse(JSON.stringify(data));
  Object.keys(searchParams).map((item) => {
    if (typeof searchParams[item] == "object") {
      if (Array.isArray(searchParams[item])) {
        if (searchParams[item].length === 0) {
          delete searchParams[item];
        }
      } else {
        if (!searchParams[item]) {
          delete searchParams[item];
        } else {
          if (Object.keys(searchParams[item]).length === 0) {
            delete searchParams[item];
          }
        }
      }
    } else {
      if (
        searchParams[item] === "null" ||
        searchParams[item] === "" ||
        searchParams[item] === undefined
      ) {
        delete searchParams[item];
      }
    }
    return searchParams;
  });
  return searchParams;
};

//Image uploading verification
export const checkImgType = (
  file,
  fileTypes = [".jpg", ".png", ".jpeg", ".gif"]
) => {
  const filePath = file?.name;
  if (filePath) {
    let isNext = false;
    let fileEnd = filePath.substring(filePath.indexOf("."));
    for (let i = 0; i < fileTypes.length; i++) {
      if (fileTypes[i] === fileEnd) {
        isNext = true;
        break;
      }
    }
    if (!isNext) {
      Toast.fail("This file type is not accepted");
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const checkImgLimit = (file, maxSize = 1) => {
  const fileMaxSize = 1024 * maxSize; //1M
  const fileSize = file.size;
  if (fileSize) {
    let size = fileSize / 1024;
    if (size > fileMaxSize) {
      Toast.fail("The file size cannot be larger than 1M!");
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const formatNumber = (value, fallback = "加载中...") => {
  // 检查是否为有效数值
  if (value !== undefined && value !== null && !isNaN(parseFloat(value))) {
    return parseFloat(value).toFixed(1);
  }
  return fallback;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from '../hooks/useLanguage';
import { useNavigate } from 'react-router-dom'; // 用于重定向

const UserProfile = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();

    const user = useSelector(state => state.user);

    if (!user || !user?.name) {
        // 如果用户信息不存在，重定向到登录页面或其他页面
        navigate('/login'); // 假设 '/login' 是登录页面的路由
        return null; // 返回 null 防止组件继续渲染
    }

    return (
        <div className="container mx-auto py-4 px-2">
            <div className="flex flex-col sm:flex-row items-center mb-4">
                <div className="w-16 h-16 sm:w-20 sm:h-20 rounded-full overflow-hidden mr-4 bg-gray-300 flex items-center justify-center mb-4 sm:mb-0">
                    <span className="text-gray-600 text-2xl">{user?.name[0]}</span>
                </div>
                <div>
                    <h1 className="text-xl sm:text-2xl font-semibold mb-1">{t.userName}: {user?.name}</h1>
                    <p className="text-gray-600 mb-1">{t.userEmail}: {user.email}</p>
                    <p className="text-gray-600">{t.userGroup}: {user.group || "无"}</p>
                </div>
            </div>
            <div className="border-t border-gray-300 pt-4">
                {/* Add more sections for user details, orders, etc. */}
            </div>
        </div>
    );
};

export default UserProfile;

import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!user?.name) {
            navigate("/login", { state: { from: location } });
        }
    }, [user]);

    return (
        <div className="flex">
            <div className="hidden md:block w-1/4 p-6 bg-white border-r text-center mt-4">
                <h2 className="text-xl font-bold mb-2">你好，{user?.name}</h2>
                <p className="text-gray-600 mb-6">Welcome to your Account</p>
                <ul className="space-y-4">
                    <li><Link className="block py-2 px-4 hover:bg-gray-200 rounded" to="/favorite">心愿清单</Link></li>
                    <li><Link className="block py-2 px-4 hover:bg-gray-200 rounded" to="/order-list">订单汇总</Link></li>
                    <li><Link className="block py-2 px-4 hover:bg-gray-200 rounded" to="/address-management">地址管理</Link></li>
                    <li><Link className="block py-2 px-4 hover:bg-gray-200 rounded" to="/user-profile">个人中心</Link></li>
                </ul>
            </div>
            <div className="w-full md:w-3/4 md:p-6">
                <Outlet />
            </div>
        </div>
    );
}

export default Sidebar;

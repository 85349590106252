// Encapsulate axios
import axios from "axios";
import "nprogress/nprogress.css";
import NProgress from "nprogress";
import { getToken, removeToken } from "./token";
import history from "./history";
import { errorMsg } from "../config/base";
import store from "../redux/configure-store";
import { setUser } from "../redux/ducks/user";
import { setCart } from "../redux/ducks/cart";
import { getBaseURL } from "./envUtils";

const http = axios.create({
  timeout: 20000,
  // eslint-disable-next-line no-undef
  baseURL: getBaseURL(),
  headers: {
    // eslint-disable-next-line no-undef
    "X-Website-Identifier": window.location.hostname,
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
// Add a request interceptor
http.interceptors.request.use(
  (config) => {
    const token = getToken() || "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    NProgress.start();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  (config) => {
    NProgress.done();
    return config;
  },
  (error) => {
    if (error && error?.response?.status === 401) {
      localStorage.setItem("preLoginPath", window.location.pathname);
      removeToken();
      setTimeout(() => {
        const newPath = window.location.pathname.includes("/admin")
          ? "/admin/login"
          : "/login";
        history.push(newPath);
        store.dispatch(setUser({}));
        store.dispatch(setCart({}));
        error.message = errorMsg["401"];
        window.location.reload();
      }, 1000);
    } else if (error && error?.response?.status === 500) {
      error.message = errorMsg["500"];
    } else if (error?.response.data.error === "店铺不存在") {
      history.push("/store-not-found");
      window.location.reload();
    }

    NProgress.done();
    return Promise.reject(error);
  }
);

export default http;

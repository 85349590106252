import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form } from "formik";
import CustomInput from '../components/CustomInput';
import { resetPasswordSchema } from "../schemas/reset-password"
import { resetPassword } from '../api/user'

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const resetToken = searchParams.get('token');
    const userEmail = searchParams.get('email');

    const onSubmit = async (values, { setSubmitting }) => {
        const { password, password_confirmation } = values;
        if (password !== password_confirmation) {
            alert('密码和确认密码不匹配！');
            setSubmitting(false);
            return;
        }

        try {
            // 使用 API 辅助函数发送请求
            await resetPassword({
                email: userEmail,
                token: resetToken,
                password: password,
                password_confirmation: password_confirmation
            });

            // 密码重置成功
            alert('密码已重置，请登录。');
            navigate('/login');
        } catch (error) {
            // 处理可能的错误
            alert(error.message || '无法重置密码，请重试。');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="flex min-h-without-header flex-1 flex-col justify-center px-6 pt-2 pb-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    重置密码
                </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="mb-4">
                        <p>正在重置密码的账户：<strong>{userEmail}</strong></p>
                    </div>
                    <Formik
                        initialValues={{ password: "", confirmPassword: "" }}
                        validationSchema={resetPasswordSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="space-y-6">
                                <CustomInput
                                    label="密码"
                                    name="password"
                                    type="password"
                                    placeholder="请输入您的密码"
                                />
                                <CustomInput
                                    label="确认密码"
                                    name="password_confirmation"
                                    type="password"
                                    placeholder="请再次输入您的密码"
                                />
                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {isSubmitting ? '重置中...' : '重置'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;

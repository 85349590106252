import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { formatNumber } from "../../utils/common"

const ProductPrice = ({ price, discount, isLargeSize = 'md', isInSlider, member_price = 0 }) => {
  const exchange_rate = useSelector((state) => state.shop?.shop?.shop_exchange_rate);
  const user = useSelector((state) => state.user);

  const textMainPriceSize = isLargeSize === 'lg'
    ? "text-sm md:text-3xl"
    : isLargeSize === 'md' ? "global-normal-text" : "text-sm";
  const textDiscountPriceSize = isLargeSize === 'lg'
    ? "text-sm md:text-xl"
    : isLargeSize === 'md' ? "global-normal-text" : "text-[10px] md:text-[12px]";
  const justifyContent = isInSlider ? "flex-start" : "";
  const flexDirection = isInSlider ? "row" : "row-reverse";

  return (
    <div>
      <div
        className={`flex rtl:justify-end rtl:self-end ltr:self-start text-left mt-2`}
        style={{ justifyContent }}
      >
        {(discount > 0) && (discount < member_price || (member_price === 0) || (member_price === null)) ? (
          <div className="flex items-end flex-wrap" style={{ flexDirection }}>
            <span className="flex flex-col">
              <del
                className={`text-left ${textDiscountPriceSize}`}
              >
                ¥{formatNumber(price * exchange_rate)}&nbsp;/&nbsp;NZ${formatNumber(price)}
              </del>
              <ins
                className={`font-bold self-end no-underline mt-1 text-rose-800 dark:text-rose-200 text-left ${textMainPriceSize}`}
              >

                ¥{formatNumber(discount * exchange_rate)}&nbsp;/&nbsp;NZ${formatNumber(discount)}
              </ins>
            </span>
          </div>
        ) : (user?.member_levels?.is_special_member === 1 && (member_price > 0)) ? (
          <div className="flex items-end flex-wrap" style={{ flexDirection }}>
            <span className="flex flex-col">
              <del
                className={`text-left ${textDiscountPriceSize}`}
              >
                ¥{formatNumber(price * exchange_rate)}&nbsp;/&nbsp;NZ${formatNumber(price)}
              </del>
              <ins
                className={`text-rose-800 dark:text-rose-200 font-bold self-end no-underline mt-1  text-left ${textMainPriceSize}`}
              >
                ¥{formatNumber(member_price * exchange_rate)}&nbsp;/&nbsp;NZ${formatNumber(member_price)}
              </ins>
            </span>
          </div>
        ) : (user?.member_levels && (member_price > 0)) ? (
          <div className="flex items-end flex-wrap" style={{ flexDirection }}>
            <span className="flex flex-col">
              <del
                className={`text-left ${textDiscountPriceSize}`}
              >
                ¥{formatNumber(price * exchange_rate)}&nbsp;/&nbsp;NZ${formatNumber(price)}
              </del>
              <ins
                className={`text-rose-800 dark:text-rose-200 font-bold self-end no-underline mt-1  text-left ${textMainPriceSize}`}
              >
                ¥{formatNumber(price * exchange_rate * (user?.member_levels?.discount / 100))}&nbsp;/&nbsp;NZ${formatNumber(price * (user?.member_levels?.discount / 100))}
              </ins>
            </span>
          </div>
        ) : (
          <div className="flex items-end flex-wrap" style={{ flexDirection }}>
            <span className="flex flex-col">
              <div
                className={`text-left ${textDiscountPriceSize}`}
              >
                &nbsp;
              </div>
              <ins
                className={`font-bold self-end no-underline mt-1 text-left ${textMainPriceSize}`}
              >

                ¥{formatNumber(price * exchange_rate)}&nbsp;/&nbsp;NZ${formatNumber(price)}
              </ins>
            </span>
          </div>
        )}
      </div>
    </div >
  );
};

ProductPrice.propTypes = {
  price: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  isLargeSize: PropTypes.bool,
  isInSlider: PropTypes.bool,
  member_price: PropTypes.number,
};

export default ProductPrice;

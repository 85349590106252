// checkoutSchema.js
import * as yup from "yup";

export const checkoutSchema = yup.object().shape({
  "recipient-name": yup.string().required("请输入收件人姓名"),
  "recipient-phone": yup.string().required("请输入收件人联系电话"),
  "recipient-address": yup.string().required("请输入收件人地址"),
  "recipient-city": yup.string().required("请输入收件人城市"),
  "recipient-country": yup.string().required("请选择收件人国家"),
  // "recipient-id": yup.string().when("shipping_type", {
  //   is: "oversea-direct",
  //   then: yup.string().required("请输入收件人身份证号码"),
  // }),
});

import { request } from "../utils";
import { FAVORITES_API } from "./constants";

export const getFavorites = (sort = "recommend", page = 1) => {
  const queryParams = `?sort=${sort}&page=${page}`;
  return request.get(`${FAVORITES_API}${queryParams}`);
};

export const AllFavorites = () => {
  return request.get(`${FAVORITES_API}/all`);
};

export const addFavorite = (data) => {
  return request.post(`${FAVORITES_API}/add`, data);
};

export const removeFavorite = (data) => {
  return request.post(`${FAVORITES_API}/remove`, data);
};

import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getBaseURL } from "../../utils/envUtils";
import { calculatePrice, calculateTotalAmount, combinedProductName } from "../../utils/calculate";
import store from "../../redux/configure-store";
import { setCart } from "../../redux/ducks/cart";
import { deleteCart } from '../../api/cart';
import { formatNumber } from "../../utils/common"

const SlideOverShippingCarts = ({ isHovered }) => {
    const [open, setOpen] = useState(false)
    const initialRender = useRef(true);
    const carts = useSelector((state) => state.cart);
    const baseURL = getBaseURL();
    const subTotal = calculateTotalAmount(carts);
    const exchange_rate = useSelector((state) => state.shop?.shop?.shop_exchange_rate);

    const goDeleteCart = async (cart) => {
        const response = await deleteCart(cart?.id)
        store.dispatch(setCart(response.data.carts));
    }

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false; // mark the initial render as done
            return; // skip the effect
        }
        setOpen(true)
    }, [isHovered])

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">购物车</Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-0.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-8">
                                                <div className="flow-root">
                                                    <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                        {carts?.length ? carts?.map((cart) => {
                                                            const { product, variant, user } = cart;
                                                            return (
                                                                <li key={product?.id} className="flex py-6">
                                                                    <div className="h-12 w-12 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 md:h-24 md:w-24">
                                                                        <img
                                                                            src={product?.avatar
                                                                                ? (product?.images?.find((image) => image?.id == product?.avatar))?.path
                                                                                : baseURL + "/images/avatar/no-picture.jpg"}
                                                                            alt={product?.imageAlt}
                                                                            className="h-full w-full object-cover object-center"
                                                                        />
                                                                    </div>

                                                                    <div className="ml-4 flex flex-1 flex-col">
                                                                        <div>
                                                                            <div className="flex justify-between text-base text-xs md:text-md font-medium text-gray-900">
                                                                                <h3>
                                                                                    <Link to={`/product/${product?.id}`}>{combinedProductName(product, variant)}</Link>
                                                                                </h3>
                                                                                <p className="ml-4">¥&nbsp;{formatNumber(calculatePrice(product, variant, user) * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{formatNumber(calculatePrice(product, variant, user))}</p>
                                                                            </div>
                                                                            <p className="mt-1 text-xs md:text-large text-gray-500">{product?.color}</p>
                                                                        </div>
                                                                        <div className="flex flex-1 items-end justify-between text-xs md:text-small">
                                                                            <p className="text-gray-500 text-xs md:text-medium">数量 {cart?.quantity}</p>

                                                                            <div className="flex">
                                                                                <button
                                                                                    type="button"
                                                                                    className="text-xs md:text-medium font-large text-indigo-600 hover:text-indigo-500"
                                                                                    onClick={() => goDeleteCart(cart)}
                                                                                >
                                                                                    移除
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }) : null}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                            <div className="flex justify-between text-base font-medium text-gray-900">
                                                <p>小计</p>
                                                <p>¥&nbsp;{formatNumber(subTotal * exchange_rate)}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{formatNumber(subTotal)}</p>
                                            </div>
                                            <p className="mt-0.5 text-sm text-gray-500">运费将在结账时计算。</p>
                                            <div className="mt-6">
                                                <Link
                                                    to="/checkout"
                                                    onClick={() => setOpen(false)}
                                                    className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                                                >
                                                    结算
                                                </Link>
                                            </div>
                                            <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                <p>
                                                    或
                                                    <button
                                                        type="button"
                                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        继续购物
                                                        <span aria-hidden="true"> &rarr;</span>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

SlideOverShippingCarts.propTypes = {
    isHovered: PropTypes.bool.isRequired,
};

export default SlideOverShippingCarts
import * as yup from "yup";

const passwordRules = /^[a-zA-Z0-9]{6,20}$/;

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "密码至少需要8个字符")
    .max(20, "密码最多只能有20个字符")
    .matches(passwordRules, {
      message: "请创建一个更强的密码",
    })
    .required("请输入您的密码"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "两次输入的密码不一致")
    .required("请确认您的密码"),
});

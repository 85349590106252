import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../components/Alerts/AlertsContext';
import CustomInput from '../components/CustomInput';
import { Formik, Form } from 'formik';
import { requestPasswordReset } from '../api/user';
import * as Yup from 'yup';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { showAlert } = useContext(AlertContext);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            // 向后端发送请求
            await requestPasswordReset({ email: values.email });

            // 请求发送成功
            setIsSubmitted(true);
            showAlert('success', '重置密码邮件已发送，请检查您的邮箱');
            navigate('/login');
        } catch (error) {
            // 请求失败
            showAlert('error', error.message || '您输入的电子邮件地址尚未注册。');
        } finally {
            setSubmitting(false);
        }
    };

    if (isSubmitted) {
        return (
            <div className="text-center">
                <h2 className="text-xl font-bold">重置邮件已发送</h2>
                <p>请检查您的邮箱进行下一步操作。</p>
            </div>
        );
    }
    const goBack = () => {
        navigate(-1); // 返回上一个页面
    };

    return (
        <div className="flex min-h-without-header flex-1 flex-col justify-center px-6 pt-2 pb-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    重置密码
                </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={Yup.object({
                            email: Yup.string().email('无效的邮箱地址').required('需要填写邮箱地址'),
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="space-y-6">
                                <CustomInput
                                    label="电子邮件地址"
                                    name="email"
                                    type="email"
                                    placeholder="请输入您的电子邮件"
                                />
                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        发送重置邮件
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <button onClick={goBack} className="mb-4 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                        返回
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;

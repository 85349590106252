// Action Types
export const FETCH_SHOP_REQUEST = "FETCH_SHOP_REQUEST";
export const FETCH_SHOP_SUCCESS = "FETCH_SHOP_SUCCESS";
export const FETCH_SHOP_FAILURE = "FETCH_SHOP_FAILURE";

// Action Creators
export const fetchShopRequest = () => ({
  type: FETCH_SHOP_REQUEST,
});

export const fetchShopSuccess = (shop) => ({
  type: FETCH_SHOP_SUCCESS,
  payload: shop,
});

export const fetchShopFailure = (error) => ({
  type: FETCH_SHOP_FAILURE,
  error,
});

// Initial State
const initialState = {
  shop: null,
  loading: false,
  error: null,
};

// Reducer
export default function shopRedux(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHOP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SHOP_SUCCESS:
      return {
        ...state,
        shop: action.payload,
        loading: false,
      };
    case FETCH_SHOP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const ON_lOADING = "ON_lOADING";
export const OFF_lOADING = "OFF_lOADING";

export const onLoading = () => ({
  type: ON_lOADING,
});

export const offLoading = () => ({
  type: OFF_lOADING,
});

const initialState = false;

export default function loadingRedux(state = initialState, action) {
  switch (action.type) {
    case ON_lOADING:
      return true;
    case OFF_lOADING:
      return false;
    default:
      return state;
  }
}

import React from "react";
import ReactDOM from "react-dom";
import { Success, Fail, Loading } from "../svg/toast-icon";
let timer = null;

/**
 *
 *  Toast.success("xxx")
 *  Toast.info("xxx")
 *  Toast.fail("xxx")
 *  Toast.loading("xxxx", true)
 *  setTimeout(() => {
 *    Toast.loading("xxx", false)
 *  }, 3000)
 * **/

const Toast = {
    info: (msg = "info", timeout = 2000) => {
        init();
        setTime(timeout);
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.render(
            <div className="box">
                <span>{msg}</span>
            </div>,
            document.getElementById("dark-toast")
        );
    },
    success: (msg = "success", timeout = 2000) => {
        init();
        setTime(timeout);
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.render(
            <div className="box">
                <Success />
                <span className="icons">{msg}</span>
            </div>,
            document.getElementById("dark-toast")
        );
    },
    fail: (msg = "fail", timeout = 2000) => {
        init();
        setTime(timeout);
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.render(
            <div className="box">
                <Fail />
                <span className="icons">{msg}</span>
            </div>,
            document.getElementById("dark-toast")
        );
    },
    loading: (msg = "loading", status) => {
        init();
        setLoading(status);
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.render(
            <div className="box">
                <i>
                    <Loading />
                </i>
                <span className="icons">{msg}</span>
            </div>,
            document.getElementById("dark-toast")
        );
    },
};

function setLoading(status) {
    let dark_toast = document.getElementById("dark-toast");
    if (status) dark_toast.style.display = "block";
    else dark_toast.style.display = "none";
}
function init() {
    clearTimeout(timer);
    let dark_toast = document.getElementById("dark-toast");
    if (dark_toast) {
        dark_toast.style.display = "block";
    } else {
        let div = document.createElement("div");
        div.setAttribute("id", "dark-toast");
        document.body.appendChild(div);
    }
}
function setTime(timeout) {
    timer = setTimeout(() => {
        let dark_toast = document.getElementById("dark-toast");
        if (dark_toast) {
            dark_toast.style.display = "none";
        }
    }, timeout);
}
export default Toast;

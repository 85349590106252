import React, { useEffect, useState, useContext } from 'react'
import { getUserAllOrders, deleteOrderById } from '../api/order';
import Pagination from '../components/Ui/Pagination';
import ProductSort from '../components/Ui/ProductSort';
import { format } from 'date-fns';
import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";
import { createPayment } from "../api/payment"
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/common"
import { AlertContext } from '../components/Alerts/AlertsContext';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getStatusValue(status) {
    switch (status) {
        case 'unpaid':
            return 1;
        case 'paid':
            return 2;
        case 'completed':
            return 3.5;
        default:
            return 0; // Default to 0 for unknown statuses
    }
}

const OrderList = () => {
    const { showAlert } = useContext(AlertContext);
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedRadioBtn, setSelectedRadioBtn] = useState("all");
    const exchange_rate = useSelector((state) => state.shop?.shop?.shop_exchange_rate);
    const { shop } = useSelector((state) => state.shop);
    const navigate = useNavigate();
    const statusMapping = {
        "all": "全部",
        "paid": "",
        "unpaid": "待付款",
        "completed": "",
        "cancelled": "已取消",
        "payment_failed": "支付失败"
    };

    const radioBtnValue = [
        "all",
        "paid",
        "unpaid",
        "completed",
        "cancelled",
    ];

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const cancelOrder = async (order) => {
        await deleteOrderById(order?.id);
        try {
            const response = await getUserAllOrders(selectedRadioBtn, currentPage);
            setOrders(response.data.orders.data);
            setTotalPages(response.data.orders.last_page);
        } catch (error) {
            console.error('Error', error);
        }
        showAlert('success', '取消订单成功。');
    };

    function onChangeHandler(e) {
        setSelectedRadioBtn(e.currentTarget?.id);
    }

    const handlePayment = async (order) => {
        if (shop?.is_direct_payment_enabled === 1) {
            try {
                // 调用 createPayment API
                const paymentResponse = await createPayment(order.order_number, order.total_price);

                // 检查响应中是否有支付页面 URL
                if (paymentResponse && paymentResponse.paymentPageUrl) {
                    // 如果存在支付页面 URL，则重定向用户到该页面
                    window.location.href = paymentResponse.paymentPageUrl;
                } else {
                    // 如果没有找到支付页面 URL，处理该情况（例如显示错误消息）
                    console.error('Payment page URL not found in response');
                }
            } catch (error) {
                // 捕获并处理任何在请求过程中出现的错误
                console.error('Payment Request Error:', error);
            }
        } else {
            navigate("/payment-info");
        }
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getUserAllOrders(selectedRadioBtn, currentPage);
                setOrders(response.data.orders.data);
                setTotalPages(response.data.orders.last_page);
            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchData();
    }, [selectedRadioBtn, currentPage]);

    return (
        <div className="bg-gray-50 mx-auto max-w-2xl sm:max-w-xl lg:max-w-7xl px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">
            <ProductSort
                selectedBtn={selectedRadioBtn}
                onChangeSelectedBtn={onChangeHandler}
                radioBtn={radioBtnValue} />
            <div className="bg-gray-50 mx-auto max-w-2xl sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
                <div className="">
                    <div className="space-y-6">
                        {orders?.map((order) => (
                            <div key={order.id} className="border-b border-t border-gray-200 bg-white shadow-sm rounded-lg">
                                <div className="px-4 py-5 sm:p-6 lg:grid lg:grid-cols-12 lg:gap-x-8">
                                    <div className="sm:flex lg:col-span-7">

                                        <div className="mt-6 sm:ml-6 sm:mt-0">
                                            <h3 className="text-base font-medium text-gray-900">
                                                订单号: #{order?.order_number}
                                            </h3>
                                            <p className="mt-2 text-sm font-medium text-gray-900">订单总金额: ¥&nbsp;{(formatNumber(order?.total_price * exchange_rate))}&nbsp;/&nbsp;NZ&nbsp;$&nbsp;{(order.total_price)}</p>
                                            <p className="mt-2 text-sm font-medium text-gray-900">订单总数量: {order.total_quantity}</p>
                                            <Link to={`/order-detail/${order.order_number}`} className="mt-2 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                                查看订单详情
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="mt-6 lg:col-span-5 lg:mt-0">
                                        <dl className="grid grid-cols-2 gap-x-6 text-sm">
                                            <div>
                                                <dt className="font-medium text-gray-900">收件人信息</dt>
                                                <dd className="mt-3 text-gray-500">
                                                    <span className="block">{order.shipping_name}</span>
                                                    <span className="block">{order.shipping_city}</span>
                                                    <span className="block">{order.shipping_address}</span>
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="font-medium text-gray-900">发件人信息</dt>
                                                <dd className="mt-3 space-y-3 text-gray-500">
                                                    <p>{order.sender_name}</p>
                                                    <p>{order.sender_address}</p>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>

                                <div className="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
                                    <h4 className="sr-only">Status</h4>
                                    <p className="text-sm font-medium text-gray-900">
                                        订单状况: {statusMapping[order.status]}  <time dateTime={order.datetime}>{format(new Date(order.updated_at), 'yyyy-MM-dd HH:mm:ss')}</time>   {order.status === 'unpaid' && (
                                            <><button
                                                className="ml-4 mt-2 px-4 py-2 bg-blue-500 text-white text-sm rounded hover:bg-blue-600"
                                                onClick={() => handlePayment(order)}
                                            >
                                                {shop?.is_direct_payment_enabled === 1 ? "微信支付" : "支付方式"}
                                            </button>
                                                <button
                                                    className="ml-4 mt-2 px-4 py-2 bg-red-500 text-white text-sm rounded hover:bg-red-600"
                                                    onClick={() => cancelOrder(order)}
                                                >
                                                    取消订单
                                                </button></>

                                        )}
                                    </p>
                                    {statusMapping[order.status] !== "已取消" ? <div className="mt-6" aria-hidden="true">
                                        <div className="overflow-hidden rounded-full bg-gray-200">
                                            <div
                                                className="h-2 rounded-full bg-indigo-600"
                                                style={{ width: `calc((${getStatusValue(order.status)} * 2 + 1) / 8 * 100%)` }}
                                            />
                                        </div>
                                        <div className="mt-6 grid grid-cols-4 sm:grid-cols-4 text-sm font-medium text-gray-600">
                                            <div className="text-indigo-600">订单生成</div>
                                            <div className='text-indigo-600 text-center'>
                                                {statusMapping[order.status]}
                                            </div>
                                            <div className={classNames((order.status === 'paid' || order.status === 'completed') ? 'text-indigo-600' : '', 'text-center')}>
                                                {(order.status === 'paid' || order.status === 'completed') ? "完成付款" : ""}
                                            </div>
                                            <div className={classNames(order.status === 'completed' ? 'text-indigo-600' : '', 'text-right')}>
                                                {order.status === 'completed' ? "已发货" : "待发货"}
                                            </div>
                                        </div>
                                    </div> : <div className="mt-6" aria-hidden="true">
                                        <div className="overflow-hidden rounded-full bg-gray-200">
                                            <div
                                                className="h-2 rounded-full"
                                                style={{ width: `calc((${getStatusValue(order.status)} * 2 + 1) / 8 * 100%)` }}
                                            />
                                        </div>
                                        <div className="mt-6 grid grid-cols-4 sm:grid-cols-4 text-sm font-medium text-gray-600">
                                            <div className="text-indigo-600">订单取消</div>
                                            <div className='text-indigo-600 text-center'>

                                            </div>
                                            <div className={classNames((order.status === 'paid' || order.status === 'completed') ? 'text-indigo-600' : '', 'text-center')}>

                                            </div>
                                            <div className={classNames(order.status === 'completed' ? 'text-indigo-600' : '', 'text-right')}>
                                                已取消
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div >
    )
};

export default OrderList;


import React, { useState } from 'react';
import ProductList from '../components/ProductList'
import Pagination from '../components/Ui/Pagination';
import { getNewestProducts } from "../api/product";
import useFetchProducts from '../hooks/useFetchProducts';
import ProductSort from '../components/Ui/ProductSort';

const NewestPage = () => {
    const [selectedRadioBtn, setSelectedRadioBtn] = useState("recommend");
    const { products, totalPages, currentPage, setCurrentPage } = useFetchProducts(getNewestProducts, selectedRadioBtn);

    const onChangeHandler = (e) => {
        setSelectedRadioBtn(e.currentTarget?.id);
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div>
            <div className="px-4 py-12 text-center sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">最新产品</h1>
                <p className="mx-auto mt-3 max-w-xl text-base text-gray-500 sm:mt-4 sm:text-lg">
                    尚新臻选，探索新西兰的最新精品。每一次更新，都是一场品质与潮流的盛宴。
                </p>
            </div>
            <ProductSort
                selectedBtn={selectedRadioBtn}
                onChangeSelectedBtn={onChangeHandler} />
            <ProductList products={products} />
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
}

export default NewestPage;
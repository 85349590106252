import React, { useEffect, useState } from 'react'
import ProductList from '../components/ProductList'
import { getNewestProducts, getUserNewestProducts, getBestSalesProducts, getUserBestSalesProducts } from "../api/product";
// import Feature from '../components/Feature';
import Banner from "../components/Banner/index"
// import PromoSection from "../components/PromoSection"
import Brands from "../components/ProductBrand";
import { useSelector } from 'react-redux';
import { useLanguage } from "../hooks/useLanguage";

const Home = () => {
  const defaultProduct = {
    id: 'loading',
    name: 'Loading...',
    image: '/path/to/loading-image.png', // 这应该是你的加载图像或占位符图像的路径
    description: 'Loading product information...',
    // ... 其他你想要的字段
  };

  const defaultProducts = Array(5).fill(defaultProduct);
  const [newestProducts, setNewestProducts] = useState([]);
  const [bestProducts, setBestProducts] = useState([]);
  const { shop } = useSelector((state) => state.shop);
  const user = useSelector(state => state.user);
  const { t } = useLanguage();

  const incentives = [
    {
      name: shop?.shop_incentive_name1,
      imageSrc: shop?.shop_incentive_image1,
      description: shop?.shop_incentive_description1,
    },
    {
      name: shop?.shop_incentive_name2,
      imageSrc: shop?.shop_incentive_image2,
      description: shop?.shop_incentive_description2,
    },
    {
      name: shop?.shop_incentive_name3,
      imageSrc: shop?.shop_incentive_image3,
      description: shop?.shop_incentive_description3,
    },
  ]

  useEffect(() => {
    async function fetchData() {
      try {
        setNewestProducts(defaultProducts)
        const response = user?.name ? await getUserNewestProducts("", "", 1) : await getNewestProducts("", "", 1);
        setNewestProducts([])
        setNewestProducts(response.data.products.data)
      } catch (error) {
        console.error("Error", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setBestProducts(defaultProducts)
        const response = user?.name ? await getUserBestSalesProducts("", 1) : await getBestSalesProducts("", 1);
        setBestProducts([])
        setBestProducts(response.data.products.data)
      } catch (error) {
        console.error("Error", error);
      }
    }

    fetchData();
  }, [])

  return (
    <>
      {/* <Feature /> */}
      <Banner />
      <ProductList title={t?.newProducts} products={newestProducts} link={"/newest-product"} />
      {/* <PromoSection /> */}
      <ProductList title="热销产品" products={bestProducts} link={"/best-product"} />
      <Brands />
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          <div className="rounded-2xl bg-gray-50 px-6 py-16 sm:p-16">
            <div className="mx-auto max-w-xl lg:max-w-none">
              <div className="text-center">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                  {/* We built our business on customer service */}
                  {shop?.shop_incentive_title}
                </h2>
              </div>
              <div className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-x-8 gap-y-10 sm:max-w-none lg:grid-cols-3">
                {incentives.map((incentive) => (
                  <div key={incentive?.name} className="text-center sm:flex sm:text-left lg:block lg:text-center">
                    <div className="sm:flex-shrink-0">
                      <div className="flow-root">
                        <img className="mx-auto" style={{ height: "8rem", width: "8rem" }} src={incentive?.imageSrc} alt={incentive?.name} />
                      </div>
                    </div>
                    <div className="mt-3 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                      <h3 className="text-sm font-medium text-gray-900">{incentive?.name}</h3>
                      <p className="mt-2 text-sm text-gray-500">{incentive?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
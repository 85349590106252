import { request } from "../utils";
import { PAYMENT_API } from "./constants";

export const createPayment = (orderNumber, totalPrice) => {
  const data = {
    order_number: orderNumber,
    total_price: totalPrice,
  };
  return request.post(`${PAYMENT_API}/create-payment`, data);
};

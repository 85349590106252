import { request } from "../utils";
import { CART_API } from "./constants";

export const getCartList = () => {
  return request.get(CART_API);
};

export const addToCart = (data) => {
  return request.post(`${CART_API}/add`, data);
};

export const deleteCart = (id) => {
  return request.post(`${CART_API}/delete/${id}`);
};

export const clearCart = () => {
  return request.post(`${CART_API}/clear`);
};

import http from "./http";
import qs from "qs";
import { onLoading, offLoading } from "../redux/ducks/loading";
import store from "../redux/configure-store";

const request = {
  counter: 0,

  post(url, params, type) {
    this.counter++;
    return new Promise((resolve, reject) => {
      store.dispatch(onLoading());
      let recode = params;
      if (!type || type !== "upload") {
        recode = qs.stringify(params);
        http
          .post(url, recode)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          })
          .finally(() => {
            this.counter--;
            if (this.counter === 0) {
              store.dispatch(offLoading());
            }
          });
      } else {
        const formData = new FormData();
        for (const key in recode) {
          // eslint-disable-next-line no-prototype-builtins
          if (recode.hasOwnProperty(key)) {
            formData.append(key, recode[key]);
          }
        }
        http
          .post(url, formData)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          })
          .finally(() => {
            this.counter--;
            if (this.counter <= 0) {
              this.counter = 0;
              store.dispatch(offLoading());
            }
          });
      }
    });
  },
  get(url, params = {}) {
    this.counter++;
    return new Promise((resolve, reject) => {
      store.dispatch(onLoading());
      http
        .get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error.response?.data.message);
        })
        .finally(() => {
          this.counter--;
          if (this.counter <= 0) {
            this.counter = 0;
            store.dispatch(offLoading());
          }
        });
    });
  },
};

export default request;


import React, { useEffect, useState } from 'react'
import { getFavorites } from "../api/favorite";
import Pagination from "../components/Ui/Pagination";
import ProductList from '../components/ProductList'
import ProductSort from '../components/Ui/ProductSort';
import { useSelector } from 'react-redux';

const FavoriteList = () => {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedRadioBtn, setSelectedRadioBtn] = useState("recommend");
    const favoriteItems = useSelector((state) => state.favorite);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const onChangeHandler = (e) => {
        setSelectedRadioBtn(e.currentTarget?.id);
    }
    useEffect(() => {
        const fetchFavoriteProducts = async () => {
            try {
                const response = await getFavorites(selectedRadioBtn, currentPage);
                const favorites = response.data.favorites.data
                const products = favorites?.map(favorite => { return favorite.product });
                setProducts(products);
                setTotalPages(response.data.favorites.last_page)
            } catch (error) {
                console.error("Error fetching brand products:", error);
                // You can also set an error state here if needed
            }
        };
        fetchFavoriteProducts();
    }, [selectedRadioBtn, currentPage, favoriteItems]);

    return (
        <div>
            <ProductSort
                selectedBtn={selectedRadioBtn}
                onChangeSelectedBtn={onChangeHandler} />
            <ProductList products={products} size={"sm"} />
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    )
}

export default FavoriteList;